import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Active } from 'src/models/Active/active';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { AssetServiceProvider } from 'src/providers/asset-service';

@Component({
  selector: 'app-change-retinol2',
  templateUrl: './change-retinol2.component.html',
  styleUrls: ['./change-retinol2.component.scss']
})
export class ChangeRetinol2Component implements OnInit {
  public selectedRetinol: Active[] = [];
  public haveRetinol = false;
  public retinolId: number;
  public allergies;
  public device;
  public retinolActive: Active = null;
  public retinol: Active;

  public lang = localStorage.getItem('appLang');

  constructor(
    private navCtrl: NgxNavigationWithDataComponent,
    public assetService: AssetServiceProvider,
    private zone: NgZone,
    public dialogRef: MatDialogRef<ChangeRetinol2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.allergies = this.data.allergies;
    this.retinolActive = this.data.retinolActive;
  }

  //func1 Close popup and navigate
  close() {
    this.dialogRef.close();
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") +  '/device', {
      baseSelected: this.navCtrl.get('baseSelected'),
      symptomsSelected: this.navCtrl.get('symptomsSelected'),
      questionAnswer: this.navCtrl.get('questionAnswer'),
      allergies: this.allergies,
      newBases: this.navCtrl.get('newBases'),
      finish: 'yes'
    });
  }

  //func2 Navigate to confirm Email page
  changeRetinol() {
    let strRetinol = JSON.stringify(this.retinolActive.internalId);
    localStorage.setItem('retinol', strRetinol);
    this.dialogRef.close();
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
      baseSelected: this.navCtrl.get('baseSelected'),
      symptomsSelected: this.navCtrl.get('symptomsSelected'),
      questionAnswer: this.navCtrl.get('questionAnswer'),
      allergies: this.allergies,
      newBases: this.navCtrl.get('newBases'),
      finish: 'yes'
    });
  }

  //func3 Check if the item is checked or not
  isSelected(item: Active): boolean {
    let obj = this.selectedRetinol.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func4 Change the statue of the check box
  clickSelectRetinol(item) {
    this.zone.run(() => {
      let obj = this.selectedRetinol.filter(p => p.internalId == item.internalId);
      if (obj != undefined && obj[0] != undefined) {
        this.selectedRetinol = this.selectedRetinol.filter(p => p.internalId != item.internalId);
      } else {
        this.selectedRetinol.push(item);
      }
    });
  }

  //func5 Get the translate name of the item with type TextIsoValue
  getImgName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func6 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }
}
