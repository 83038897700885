import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ClinicServiceProvider } from 'src/providers/clinic-service';
import { Clinic } from 'src/models/clinic/Clinic';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Globals } from '../globals';
import { CountryServiceProvider } from 'src/providers/country-service';
import { Country } from 'src/models/Country/Country';


@Component({
  selector: 'app-welcome-recommender',
  templateUrl: './welcome-recommender.component.html',
  styleUrls: ['./welcome-recommender.component.scss']
})
export class WelcomeRecommenderComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  public allClinics: Clinic[];
  public clinicname: string;
  public name: string = '';
  public clinicExist: boolean = false;
  public clinicEmail: string;
  public clinicId: string;
  private navE: boolean;
  public country: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private clinicservice: ClinicServiceProvider,
    private countryService: CountryServiceProvider,
    private route: ActivatedRoute,
    public navCtrl: NgxNavigationWithDataComponent,
    private globals: Globals
  ) {
    this.navE = globals.navE;
  }

  ngOnInit() {
    this.globals.lastScreen = false;
    sessionStorage.clear();
    this.globals.navE = false;
    this.globals.nav1Per = 0;
    this.globals.nav2Per = 0;
    this.globals.nav3Per = 0;
    localStorage.removeItem('haveRetinol');
    localStorage.removeItem('retinol');
    this.route.params.subscribe((param: Params) => {
      this.clinicname = param.clinicname;
    });
    /* this.route.params.subscribe((param: Params) => {
      this.country = param.countryN;
    });
    if (this.country) {
      this.countryService.checkCountry(this.country).subscribe(
        (data: boolean) => {
          if (data == true) {
            this.countryService.getCountry(this.country).subscribe(
              (data: Country) => {
                console.log(data);
              });
          } else {
            this.navCtrl.navigate('/');
          }
        }
      );
    } */
    /* if (this.country) {
      this.countryService.getCountry(this.country).subscribe(
        (res: Country) => {                           //Next callback
          console.log('response received' + res);
        },
        (error) => {
          this.navCtrl.navigate('/');                        //Error callback
          //console.error('error caught in component'+"111");

          //throw error;   //You can also throw the error to a global error handler
        }

      );
    } */

    /* this.clinicservice.getClinicbyName(this.clinicname).subscribe(
      (data: Clinic) => {
        this.clinicEmail = data.email;
        this.clinicId = data.internalId + '';
      },
      (error) => {
        this.navCtrl.navigate('/');//Error callback
        //console.error('error caught in component'+"111");

        //throw error;   //You can also throw the error to a global error handler
      }); */

    if (this.clinicname) {
      this.clinicservice.checkClinicbyName(this.clinicname).subscribe(
        (data: boolean) => {
          if (data == true) {
            this.clinicservice.getClinicbyName(this.clinicname).subscribe(
              (data: Clinic) => {
                this.clinicEmail = data.email;
                this.clinicId = data.internalId + '';
              });
          } else {
            this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/');
          }
        }
      );
    }

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    //console.log('Hello!!!');
    window.scrollTo(0, 0);
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
  }

  //func1
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //func2 Navigate to the route /base-step1
  gobaseest1() {
    let country = localStorage.getItem("appCountry");
    if(country == null || country == ""){
      localStorage.setItem("appCountry","int");
    }
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/base-step1', {
      clinicEmail: this.clinicEmail,
      clinicId: this.clinicId
    });
  }
}
