import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Active } from 'src/models/Active/active';
import { Base } from 'src/models/base/base';
import { CustomerFinalRecommender } from 'src/models/customerFinalRecommender/customerFinalRecommender';
import { Device } from 'src/models/Device/device';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { SymptomAssetResult } from 'src/models/symptom/symptomAssetResult';
import { Symptom } from 'src/models/symptom/symtom';
import { BaseServiceProvider } from 'src/providers/base-service';
import { CustomerFinalRecommenderServiceProvider } from 'src/providers/customer-final-recommender-service';
import { DeviceServiceProvider } from 'src/providers/device-service';
import { PrestashopURLsProvider } from 'src/providers/prestaShopURLs';
import { SymptomServiceProvider } from 'src/providers/symptom-service';
import { Globals } from '../globals';
import { TermsAndConditionsComponent } from './../terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-confirmation-email',
  templateUrl: './confirmation-email.component.html',
  styleUrls: ['./confirmation-email.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ConfirmationEmailComponent implements OnInit {
  public questionAnswers: QuestionAnswer[] = [];
  public baseSelected: Base;
  public baseNew = 0;
  private newBases: Base[] = [];
  private symptomsSelected: Symptom[];
  private Allergy: Active[] = [];
  public isActiveP;
  public emailCustomer: string = '';
  private idsToShop: string;
  private clinicEmail: string = '';
  public lstResultSymptoms: SymptomAssetResult[];
  public urlCart: string;
  public deviceId: number = 0;
  public allergieIds: number[] = [];
  public allergiesStr: string = "";
  public device: number;
  public haveRetinol = false;
  public retinol: number = 0;
  public retinolActive: number;
  private customerFinalRecommender: CustomerFinalRecommender = null;
  public lesielleDevice: Device;

  userEmail = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ])
  })

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    public sypmtomService: SymptomServiceProvider,
    public psUrlBase: PrestashopURLsProvider,
    private globals: Globals,
    private toastr: ToastrService,
    private customer_final_recommender_service: CustomerFinalRecommenderServiceProvider,
    private navCtrl: NgxNavigationWithDataComponent,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.globals.nav1Per = 100;
    this.globals.nav2Per = 100;
    this.globals.nav3Per = 100;
  }

  //func1 Sending mail to the consumer & open the spinner
  async send() {
    this.baseSelected = this.navCtrl.get('baseSelected');
    this.symptomsSelected = this.navCtrl.get('symptomsSelected');
    this.questionAnswers = this.navCtrl.get('questionAnswer');
    this.newBases = this.navCtrl.get('newBases');
    this.Allergy = this.navCtrl.get('allergies');
    let language = localStorage.getItem('appLang');
    if (language==null){
      language = 'es';
    }
    this.device = this.navCtrl.get('device');
    let clinicId = this.navCtrl.get('clinicId');
    if (clinicId == null) {
      clinicId = '0';
    }
    let sypmtIds: number[] = this.symptomsSelected.map(function (a) {
      return a.internalId;
    });
    let questIds: number[] = this.questionAnswers.map(function (a) {
      return a.internalId;
    });
    this.idsToShop = this.baseSelected.idPs.toString();
    if (this.Allergy != null) {
      this.allergieIds = this.Allergy.map(function (a) {
        return a.internalId;
      });
      this.allergiesStr = this.allergieIds.join(';');
    }
    let customerfinalrecommender = new CustomerFinalRecommender();
    if (this.emailCustomer == '') {
      customerfinalrecommender.customerEmail = 'lesielleapp@gmail.com';
    } else {
      customerfinalrecommender.customerEmail = this.emailCustomer;
    }

    this.retinolActive = JSON.parse(localStorage.getItem('retinol'));

    if (this.retinolActive != null) {
      this.retinol = this.retinolActive;
    }
    if (this.newBases != null) {
      this.newBases.forEach(
        e => {
          this.baseNew = e.internalId;
        });
    }
    let country = localStorage.getItem("appCountry");
    this.urlCart = this.psUrlBase.getFrontUrlBaseByCountryCode(country, language);
    this.urlCart += 'testr?p=';
    this.customer_final_recommender_service.addCustomerFinalRecommender(customerfinalrecommender.customerEmail, this.baseSelected.internalId, this.baseNew, this.allergieIds, sypmtIds, questIds, clinicId, this.device, this.urlCart, language, this.retinol).subscribe(data=>{
      sessionStorage.setItem('recommender', JSON.stringify(data));
    });
    if (this.emailCustomer != null) {
      this.customer_final_recommender_service.sendFullRecomendation(this.emailCustomer, language, this.baseSelected.internalId, this.baseNew, this.allergiesStr, sypmtIds, questIds, this.clinicEmail, this.urlCart, language, this.retinol)
        .subscribe((data: SymptomAssetResult[]) => {
        },
          (error1) => {
            console.log('Error-Mail', error1);
          });
    }

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.modalService.dismissAll();
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/final-recommender', {
        customerfinalrecommender: this.customerFinalRecommender,
        baseSelected: this.navCtrl.get('baseSelected'),
        symptomsSelected: this.navCtrl.get('symptomsSelected'),
        questionAnswer: this.navCtrl.get('questionAnswer'),
        allergiesSelected: this.navCtrl.get('allergiesSelected'),
        newBases: this.navCtrl.get('newBases'),
        device: this.navCtrl.get('device'),
        customerEmail: customerfinalrecommender.customerEmail,
        finish: 'yes'
      });
    }, 2500);
  }

  //func2 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func3 Open terms and conditions
  openTerms() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (window.innerWidth < 767) {
      dialogConfig.maxWidth = "80%";
    } else if (window.innerWidth < 1024) {
      dialogConfig.maxWidth = "80%";
    } else {
      dialogConfig.maxWidth = "900px";
    }
    const dialogRef = this.dialog.open(TermsAndConditionsComponent, dialogConfig);
  }

  //func9 Show the alert telling that this combination is not available
  showAlert() {
    let str1: string;
    let str2: string;
    this.translate.get('ADD_EMAIL').subscribe((res: string) => {
      str1 = res;
    });
    this.translate.get('ADD_EMAIL').subscribe((res: string) => {
      str2 = res;
    });
    this.toastr.error(str2, '', {
      timeOut: 2000,
      messageClass: 'text',
      positionClass: 'toast-bottom-right'
    });
  }
}
