import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/Rx';
import {Customer} from '../models/customer/customer';
import {GlobalEnvironmentProvider} from './global-service';

/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CustomerApiRestProvider {
  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  customerLogin(email: string, passWd: string) {
    let url = this.apiUrl + 'token/GetToken?email=' + email + '&passwd=' + passWd;
    return this.http.get(url);
  }

  //TODO: Must be post
  customerLoginFb(email: string, idFb: string, tokenFb: string, name: string, firstName: string, location: string, lang: string) {
    let url = this.apiUrl + 'token/GetTokenFb?email=' + email + '&fbid=' + idFb + '&token=' + tokenFb + '&name=' + name + '&firstName=' + firstName + '&geolocation=' + location + '&lang=' + lang;
    return this.http.get(url);
  }

  checkToken() {
    let url = this.apiUrl + 'token/checktoken';
    return this.http.post(url, {});
  }

  getCustomerAppData() {
    let id = localStorage.getItem('userId');
    let url = this.apiUrl + 'customer/GetCustomer?userid=' + id;
    return this.http.get(url);
  }

  ///TODO Mush be http post
  setAllergies(userId: string, allergiesIds: number[]) {
    let url = this.apiUrl + 'Customer/SetAllergies?u=' + userId + '&a=' + allergiesIds.join(';');
    return this.http.post(url, null);
  }

  registerUser(customer: Customer) {
    let url = this.apiUrl + 'customer/RegisterUser';
    let jsonBody = JSON.stringify(customer);
    return this.http.post(url, jsonBody);
  }

  GetUserByEmail(email: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'customer/GetUserByEmail?email=' + email;
    return this.http.get(url, {headers: headers});
  }
}
