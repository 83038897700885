import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { PrestashopURLsProvider } from 'src/providers/prestaShopURLs';

@Component({
  selector: 'app-close-recommender',
  templateUrl: './close-recommender.component.html',
  styleUrls: ['./close-recommender.component.scss']
})
export class CloseRecommenderComponent implements OnInit {
  public popupNumber: number;

  public frontUrl: string;

  constructor(
    public dialogRef: MatDialogRef<CloseRecommenderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public psUrlBase: PrestashopURLsProvider,
  ) { }

  ngOnInit() {
    this.popupNumber = this.data;
    
    let lang = localStorage.getItem('appLang');
    let country = localStorage.getItem("appCountry");
    this.frontUrl = this.psUrlBase.getFrontUrlBaseByCountryCode(country, lang);
  }

  //fun1 Go the home page
  goHomePage() {
    this.router.navigate(['/' + localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang")]);
    this.dialogRef.close();
  }

  //func2 Close the popup
  onClose() {
    this.dialogRef.close();
  }

}
