import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { Symptom } from 'src/models/symptom/symtom';
import { SymptomServiceProvider } from 'src/providers/symptom-service';
import { AddionalBaseSt1Component } from '../addional-base-st1/addional-base-st1.component';
import { Globals } from '../globals';

@Component({
  selector: 'app-symptom-st1',
  templateUrl: './symptom-st1.component.html',
  styleUrls: ['./symptom-st1.component.scss']
})
export class SymptomSt1Component implements OnInit {
  public allSymptoms: Symptom[];
  public selectedSymptoms: Symptom[] = [];
  public symp_arru_enveje: Symptom[];
  public symp_apagada_manchi: Symptom[];
  public symp_acne_poros: Symptom[];
  public symp_roje_rosa: Symptom[];
  public sypm_hidra: Symptom[];

  public lang = localStorage.getItem('appLang');

  constructor(
    public symptomService: SymptomServiceProvider,
    private router: Router,
    private zone: NgZone,
    private globals: Globals,
    private dialog: MatDialog,
    public navCtrl: NgxNavigationWithDataComponent
    
  ) {

    if(localStorage.getItem("appCountry") != "tr" && localStorage.getItem("appCountry") != "us" && localStorage.getItem("appCountry") != "uk"){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        baseSelected: this.navCtrl.get('baseSelected'),
        baseSelected0: this.navCtrl.get('baseSelected0'),
        allBases: this.navCtrl.get('allBases'),
        clinicId: this.navCtrl.get('clinicId')
      };
      if (window.innerWidth < 767) {
        dialogConfig.maxWidth = "80%";
      } else if (window.innerWidth < 1024) {
        dialogConfig.maxWidth = "80%";
      } else {
        dialogConfig.maxWidth = "900px";
      }
      this.dialog.open(AddionalBaseSt1Component, dialogConfig);
    }
    
  }

  ngOnInit() {
    this.globals.nav1Per = 100;
    this.globals.nav2Per = 33;
    this.navCtrl.get('baseSelected');
    this.symptomService.getAllSypmtoms().subscribe(
      (data: Symptom[]) => {
        this.allSymptoms = data;
        this.orderSymptomsToView(this.allSymptoms);
      });
  }

  //func1 Order the Symptom by order
  orderSymptomsToView(symps: Symptom[]) {
    this.symp_arru_enveje = symps.filter(p => p.internalId == 5 || p.internalId == 10);
    this.symp_apagada_manchi = symps.filter(p => p.internalId == 6 || p.internalId == 3);
    this.symp_acne_poros = symps.filter(p => p.internalId == 1 || p.internalId == 8 || p.internalId == 7 || p.internalId == 9);
    this.symp_roje_rosa = symps.filter(p => p.internalId == 4 || p.internalId == 2);
    this.sypm_hidra = symps.filter(p => p.internalId == 11);
  }

  //func2 Change the statue of the check box
  clickSelectSymptom(item) {
    this.zone.run(() => {
      let obj = this.selectedSymptoms.filter(p => p.internalId == item.internalId);
      if (obj != undefined && obj[0] != undefined) {
        this.selectedSymptoms = this.selectedSymptoms.filter(p => p.internalId != item.internalId);
      } else {
        this.selectedSymptoms.push(item);
      }
    });
  }

  //func3 Check if the user made all his choices and navigate to the route /active-step2
  goSymptomSt2() {
    if (this.selectedSymptoms.length != 0) {
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/active-step2', {
        baseSelected: this.navCtrl.get('baseSelected'),
        newBases: this.navCtrl.get('newBases'),
        allBases: this.navCtrl.get('allBases'),
        clinicId: this.navCtrl.get('clinicId'),
        symptomsSelected: this.selectedSymptoms
      });
    }
  }

  //func4 Check if the item is checked or not
  isSelected(item: Symptom): boolean {
    let obj = this.selectedSymptoms.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func5 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func6 Go back to the last page
  goback() {
    if(localStorage.getItem("appCountry") == "tr" || localStorage.getItem("appCountry") == "us" || localStorage.getItem("appCountry") == "uk"){
      this.router.navigate(['/base-step1']);
    }else{
      this.router.navigate(['/base-step2']);
    }
  }
}
