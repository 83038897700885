import { Component, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { Globals } from '../globals';
import { CloseRecommenderComponent } from '../close-recommender/close-recommender.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, NavigationStart, Params, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  globals: Globals;
  public languageSelected: string;
  public countrySelected: string;
  public availableLanguages:  {value: string,text:string}[];
  public currentUrl: string;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private zone: NgZone,
    private _location: Location,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    globals: Globals
  ) {
    this.globals = globals;
  }

  ngOnInit() {

    this.translate.onLangChange.subscribe(() => {
      this.languageSelected = localStorage.getItem('appLang');
      this.countrySelected = localStorage.getItem('appCountry');
      this.resetAvailablesLanguages();
    })
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
      }
      this.languageSelected = localStorage.getItem('appLang');
      this.countrySelected = localStorage.getItem('appCountry');
      this.resetAvailablesLanguages();
    })
    this.route.params.subscribe((param: Params) => {
      let lang = param['lang'];
      if(lang == "tr"){
        this.translate.use("tr");
        localStorage.setItem('appLang', "tr");
        this.translate.setDefaultLang("tr");
      }else if(lang == "es"){
        this.translate.use("es");
        localStorage.setItem('appLang', "es");
        this.translate.setDefaultLang("es");
      }else if(lang == "en"){
        this.translate.use("en");
        localStorage.setItem('appLang', "en");
        this.translate.setDefaultLang("en");
      }
      this.languageSelected = localStorage.getItem('appLang');
      this.countrySelected = localStorage.getItem('appCountry');
      this.resetAvailablesLanguages();
    });
  }

  //func1 Change the language of the recommender
  changeLanguage(ev: Event) {
    let target =  (<HTMLSelectElement>ev.target);
    let value =  target.value.toLowerCase();
    switch (value) {
      case "es":
        localStorage.setItem('appLang', "es");
        this.translate.setDefaultLang("es");
        this.translate.use("es");
        break;
      case "en":
        localStorage.setItem('appLang', "en");
        this.translate.setDefaultLang("en");
        this.translate.use("en");
        break;
      case "tr":
        localStorage.setItem('appLang', "tr");
        this.translate.setDefaultLang("tr");
        this.translate.use("tr");
        break;
      default:
        break;
      
    }
    let location = "/" + localStorage.getItem("appCountry") + "/" + localStorage.getItem("appLang") + "/" + (this._location.path().split("/")[3] != null ? this._location.path().split("/")[3] : "");
    this._location.go(location)
    
  }
  resetAvailablesLanguages(){
    this.countrySelected = localStorage.getItem("appCountry");
    if(this.countrySelected == "int"){
      this.availableLanguages = [{value: "es",text:"ES"},{value: "en",text:"EN"},{value: "tr",text:"TR"}]
    }else{
      this.availableLanguages = [];
    }
  }

  //func2 Change language To English
  /*changeLanguageEn() {
    localStorage.setItem('appLang', "en");
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    localStorage.setItem('appCountry', "int");
    this.countrySelected = localStorage.getItem("appCountry");
    this.resetAvailablesLanguages();
    let location = "/" + localStorage.getItem("appCountry") + "/" + localStorage.getItem("appLang") + "/" + (this._location.path().split("/")[3] != null ? this._location.path().split("/")[3] : "");
    console.log(location);
    this._location.go(location)
  }
  //func2 Change language To English
  changeLanguageUS() {
    localStorage.setItem('appLang', "en");
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    localStorage.setItem('appCountry', "us");
    this.countrySelected = localStorage.getItem("appCountry");
    this.resetAvailablesLanguages();
    let location = "/" + localStorage.getItem("appCountry") + "/" + localStorage.getItem("appLang") + "/" + (this._location.path().split("/")[3] != null ? this._location.path().split("/")[3] : "");
    console.log(location);
    this._location.go(location)
  }

  //func3 Change language To Spanish
  changeLanguageEs() {
    localStorage.setItem('appLang', "es");
    this.translate.setDefaultLang("es");
    this.translate.use("es");
    localStorage.setItem('appCountry', "es");
    this.countrySelected = localStorage.getItem("appCountry");
    this.resetAvailablesLanguages();
    let location = "/" + localStorage.getItem("appCountry") + "/" + localStorage.getItem("appLang") + "/" + (this._location.path().split("/")[3] != null ? this._location.path().split("/")[3] : "");
    this._location.go(location)
  }

  //func3 Change language To turkish
  changeLanguageTr() {
    localStorage.setItem('appLang', "tr");
    this.translate.setDefaultLang("tr");
    this.translate.use("tr");
    localStorage.setItem('appCountry', "tr");
    this.countrySelected = localStorage.getItem("appCountry");
    this.resetAvailablesLanguages();
    let location = "/" + localStorage.getItem("appCountry") + "/" + localStorage.getItem("appLang") + "/" + (this._location.path().split("/")[3] != null ? this._location.path().split("/")[3] : "");
    this._location.go(location)
  }*/

  //func4 Go back
  backClicked() {
    let matches = this.currentUrl.match(/^\/([a-zA-Z0-9]+)\/(..)\/(.+)/);
    if((localStorage.getItem("appCountry") == "tr" || localStorage.getItem("appCountry") == "us" || localStorage.getItem("appCountry") == "uk") && matches != null && matches[3] != null && matches[3] == "active-step1"){
      this.router.navigate([ "/" + localStorage.getItem("appCountry") + "/" + localStorage.getItem("appLang") + '/base-step1']);
    }else{
      this._location.back();
    }
  }

  //func5 Open close recommender dialog
  closeRecommender(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = id;

    dialogConfig.width = "500px";
    if (window.innerWidth < 540) {
      dialogConfig.width = "80%!important";
    } else if (window.innerWidth < 720) {
      dialogConfig.width = "500px";
    } else {
      dialogConfig.width = "500px";
    }
    this.dialog.open(CloseRecommenderComponent, dialogConfig);
  }
}
