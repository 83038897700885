import { LesielleVideoComponent } from './../lesielle-video/lesielle-video.component';
import { Active } from 'src/models/Active/active';
import { ChangeRetinol2Component } from '../change-retinol2/change-retinol2.component';
import { DeviceServiceProvider } from 'src/providers/device-service';
import { Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Device } from 'src/models/Device/device';
import { CustomerFinalRecommenderServiceProvider } from 'src/providers/customer-final-recommender-service';
import { Base } from 'src/models/base/base';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { Symptom } from 'src/models/symptom/symtom';
import { SymptomAssetResult } from 'src/models/symptom/symptomAssetResult';
import { CustomerFinalRecommender } from 'src/models/customerFinalRecommender/customerFinalRecommender';
import { AssetServiceProvider } from 'src/providers/asset-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from '../globals';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceComponent implements OnInit {
  public devices: Device[];
  public selectedDevices: Device[] = [];
  public itemSelected: Device = null;
  public deviceId: number;

  public questionAnswers: QuestionAnswer[] = [];
  public baseSelected: Base;
  public baseNew = 0;
  private symptomsSelected: Symptom[];
  private Allergy: Active[] = [];
  public isActiveP;
  public emailCustomer: string = '';
  public lstResultSymptoms: SymptomAssetResult[];
  public urlCart: string;
  public allergieIds: number[];
  public allergiesStr: string = "";
  public device: number;
  public haveRetinol: string;
  private idsToShop: string;
  public retinolActive: number;
  public enableChoice = true;
  public lang;


  constructor(
    public addDeviceService: DeviceServiceProvider,
    private modalService: NgbModal,
    private zone: NgZone,
    private globals: Globals,
    private spinner: NgxSpinnerService,
    public assetService: AssetServiceProvider,
    private navCtrl: NgxNavigationWithDataComponent,
    private customer_final_recommender_service: CustomerFinalRecommenderServiceProvider,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.lang = localStorage.getItem('appLang');
    this.globals.nav1Per = 100;
    this.globals.nav2Per = 100;
    this.globals.nav3Per = 50;
    this.questionAnswers = this.navCtrl.get('questionAnswer');
    this.spinner.show();
    this.existRetinol();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    this.addDeviceService.getAllDevice().subscribe(
      (data: Device[]) => {
        this.devices = data;
      }
    );
  }

  //func1 Check if the item is checked or not
  isSelected(item: Device): boolean {
    let obj = this.selectedDevices.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func2 Change the statue of the check box
  clickSelectDevice(item) {
    this.zone.run(() => {
      if (this.itemSelected != undefined && this.itemSelected.internalId == item.internalId) {
        this.itemSelected = undefined;
        this.selectedDevices = [];
      } else {
        this.itemSelected = item;
        this.selectedDevices = [];
        this.selectedDevices.push(item);
      }
    });
  }

  //func3 Go to the next page
  next() {
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/registration', {
      baseSelected: this.navCtrl.get('baseSelected'),
      symptomsSelected: this.navCtrl.get('symptomsSelected'),
      questionAnswer: this.questionAnswers,
      allergies: this.navCtrl.get('allergies'),
      clinicId: this.navCtrl.get('clinicId'),
      newBases: this.navCtrl.get('newBases'),
      finish: 'yes'
    });
  }

  //func4 Add device to the card
  addDevice() {
    if (this.selectedDevices.length != 0) {
      this.selectedDevices.forEach((d: Device) => {
        this.deviceId = d.internalId;
      });
      this.modalService.dismissAll();
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/registration', {
        baseSelected: this.navCtrl.get('baseSelected'),
        symptomsSelected: this.navCtrl.get('symptomsSelected'),
        questionAnswer: this.questionAnswers,
        allergies: this.navCtrl.get('allergies'),
        clinicId: this.navCtrl.get('clinicId'),
        device: this.deviceId,
        newBases: this.navCtrl.get('newBases'),
        finish: 'yes'
      });
      /*} */
    }
  }

  //func5 check if the user recommender has retinol !
  existRetinol() {
    this.baseSelected = this.navCtrl.get('baseSelected');
    this.symptomsSelected = this.navCtrl.get('symptomsSelected');
    let newBases = this.navCtrl.get('newBases');
    this.Allergy = this.navCtrl.get('allergies');
    this.device = this.navCtrl.get('device');
    let clinicId = '0';

    let sypmtIds: number[] = this.symptomsSelected.map(function (a) {
      return a.internalId;
    });
    let questIds: number[] = this.questionAnswers.map(function (a) {
      return a.internalId;
    });
    this.idsToShop = this.baseSelected.idPs.toString();
    if (this.Allergy != null) {
      this.allergieIds = this.Allergy.map(function (a) {
        return a.internalId;
      });
      this.allergiesStr = this.allergieIds.join(';');
    }
    let customerfinalrecommender = new CustomerFinalRecommender();

    customerfinalrecommender.customerEmail = 'lesielleapp@gmail.com';
    customerfinalrecommender.clinicId = clinicId + '';

    let lang = localStorage.getItem('appLang');
    this.customer_final_recommender_service.ExistRetinolActive(this.baseSelected.internalId, sypmtIds, questIds).subscribe(
      (retinol: Active) => {
        if (retinol != null) {
          this.assetService.getRetinolAsset().subscribe((r: Active) => {


            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
              allergies: this.navCtrl.get('allergies'),
              device: this.deviceId,
              retinolActive: r,
            };
            if (window.innerWidth < 767) {
              dialogConfig.maxWidth = "80%";
            } else if (window.innerWidth < 1024) {
              dialogConfig.maxWidth = "80%";
            } else {
              dialogConfig.maxWidth = "500px";
            }
            this.dialog.open(ChangeRetinol2Component, dialogConfig);
          },
            error => {
              console.log('Error', error);
            });
        }
      },
      error => {
        console.log('Error', error);
      });
  }

  //func6 Get the translate name of the item with type TextIsoValue
  getImgName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func7 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func8 Open the base information 2
  open() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.innerWidth < 767) {
      dialogConfig.maxWidth = "80%";
    } else if (window.innerWidth < 1024) {
      dialogConfig.maxWidth = "80%";
    } else {
      dialogConfig.maxWidth = "900px";
    }
    const dialogRef = this.dialog.open(LesielleVideoComponent, dialogConfig);
  }
}
