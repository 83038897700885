import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  languageSelected: string = 'en';


  constructor(
    public translate: TranslateService,
  ) {
  }

  ngOnInit() {
  }

  //func1 Change the language
  next() {
    console.log(this.languageSelected);
    localStorage.setItem('appLang', this.languageSelected);
    this.translate.setDefaultLang(this.languageSelected);
  }
}
