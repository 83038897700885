import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatButtonModule, MatDialogModule, MatIconModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RecaptchaModule } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ClinicServiceProvider } from 'src/providers/clinic-service';
import { PrestashopURLsProvider } from 'src/providers/prestaShopURLs';
import { AssetServiceProvider } from '../providers/asset-service';
import { BaseServiceProvider } from '../providers/base-service';
import { CustomerFinalRecommenderServiceProvider } from '../providers/customer-final-recommender-service';
import { CustomerApiRestProvider } from '../providers/customer-service';
import { GlobalEnvironmentProvider } from '../providers/global-service';
import { SymptomServiceProvider } from '../providers/symptom-service';
import { DeviceServiceProvider } from './../providers/device-service';
import { QuestionBaseServiceProvider } from './../providers/question-base-service';
import { AddDeviceGuardService } from './add-device/add-device-guard.service';
import { AddDeviceComponent } from './add-device/add-device.component';
import { AddionalBaseSt1Component } from './addional-base-st1/addional-base-st1.component';
import { AllergiesGuardService } from './allergies/allergies-guard.service';
import { AllergiesComponent } from './allergies/allergies.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseInfoSt1Component } from './base-info-st1/base-info-st1.component';
import { BaseInfoSt2Component } from './base-info-st2/base-info-st2.component';
import { BaseSt1GuardService } from './base-st1/base-st1-guard.service';
import { BaseSt1Component } from './base-st1/base-st1.component';
import { BaseSt2GuardService } from './base-st2/base-st2-guard.service';
import { BaseSt2Component } from './base-st2/base-st2.component';
import { BaseTutoSt1Component } from './base-tuto-st1/base-tuto-st1.component';
import { ChangeRetinol2Component } from './change-retinol2/change-retinol2.component';
import { ConfirmationEmailGuardService } from './confirmation-email/confirmation-email-guard.service';
import { ConfirmationEmailComponent } from './confirmation-email/confirmation-email.component';
import { FinalRecommendationGuardService } from './final-recommendation/final-recommendation-guard.service';
import { FinalRecommendationComponent } from './final-recommendation/final-recommendation.component';
import { FooterComponent } from './footer/footer.component';
import { ForgetEmailComponent } from './forget-email/forget-email.component';
import { Globals } from './globals';
import { LanguageComponent } from './language/language.component';
import { NavComponent } from './nav/nav.component';
import { NormalBasePopupComponent } from './normal-base-popup/normal-base-popup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecommenderInfo1Component } from './recommender-info1/recommender-info1.component';
import { RecommenderInfo2Component } from './recommender-info2/recommender-info2.component';
import { RosaceaPopupComponent } from './rosacea-popup/rosacea-popup.component';
import { SymptomSt1GuardService } from './symptom-st1/symptom-st1-guard.service';
import { SymptomSt1Component } from './symptom-st1/symptom-st1.component';
import { SymptomSt2GuardService } from './symptom-st2/symptom-st2-guard.service';
import { SymptomSt2Component } from './symptom-st2/symptom-st2.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WelcomeRecommenderComponent } from './welcome-recommender/welcome-recommender.component';
import { CloseRecommenderComponent } from './close-recommender/close-recommender.component';
import { LesielleVideoComponent } from './lesielle-video/lesielle-video.component';
import { CountryServiceProvider } from 'src/providers/country-service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    WelcomeRecommenderComponent,
    BaseSt1Component,
    BaseSt2Component,
    BaseInfoSt1Component,
    BaseInfoSt2Component,
    SymptomSt1Component,
    SymptomSt2Component,
    RecommenderInfo1Component,
    RecommenderInfo2Component,
    FinalRecommendationComponent,
    AllergiesComponent,
    LanguageComponent,
    FooterComponent,
    ConfirmationEmailComponent,
    PageNotFoundComponent,
    TermsAndConditionsComponent,
    AddDeviceComponent,
    AddionalBaseSt1Component,
    ChangeRetinol2Component,
    RosaceaPopupComponent,
    NormalBasePopupComponent,
    BaseTutoSt1Component,
    ForgetEmailComponent,
    CloseRecommenderComponent,
    LesielleVideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpModule,
    NgbModule,
    NgxSpinnerModule,
    RecaptchaModule,
    AngularFontAwesomeModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    FormsModule,
    MatTooltipModule,
    MatButtonModule,
    NgxSkeletonLoaderModule
  ],
  exports: [
    MatDialogModule
  ],
  entryComponents: [
    BaseInfoSt1Component,
    BaseInfoSt2Component,
    RecommenderInfo1Component,
    RecommenderInfo2Component,
    AddionalBaseSt1Component,
    ChangeRetinol2Component,
    RosaceaPopupComponent,
    NormalBasePopupComponent,
    BaseTutoSt1Component,
    ForgetEmailComponent,
    TermsAndConditionsComponent,
    CloseRecommenderComponent,
    LesielleVideoComponent
  ],
  providers: [
    Globals,
    GlobalEnvironmentProvider,
    BaseServiceProvider,
    AssetServiceProvider,
    CustomerApiRestProvider,
    ClinicServiceProvider,
    CountryServiceProvider,
    DeviceServiceProvider,
    CustomerFinalRecommenderServiceProvider,
    SymptomServiceProvider,
    BaseSt1GuardService,
    BaseSt2GuardService,
    SymptomSt1GuardService,
    SymptomSt2GuardService,
    AllergiesGuardService,
    AddDeviceGuardService,
    FinalRecommendationGuardService,
    ConfirmationEmailGuardService,
    QuestionBaseServiceProvider,
    CookieService,
    PrestashopURLsProvider,
    NgxNavigationWithDataComponent,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
