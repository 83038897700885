import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GlobalEnvironmentProvider} from './global-service';

/*
  Generated class for the BaseServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ClinicServiceProvider {
  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getAllClinics() {
    let url = this.apiUrl + 'clinic/GetClinics';
    return this.http.get(url);
  }

  getClinicbyName(name: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'clinic/GetClinic?clinicName=' + name;
    return this.http.get(url, {headers: headers});
  }

  checkClinicbyName(name: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'clinic/verifClinic?clinicName=' + name;
    return this.http.get(url, {headers: headers});
  }
}
