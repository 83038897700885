import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lesielle-video',
  templateUrl: './lesielle-video.component.html',
  styleUrls: ['./lesielle-video.component.scss']
})
export class LesielleVideoComponent implements OnInit {
  public lang;

  constructor(
    public dialogRef: MatDialogRef<LesielleVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    ) { }

  ngOnInit() {
    this.lang = localStorage.getItem('appLang');
  }
  //func2 Close the popup
  onClose() {
    this.dialogRef.close();
  }

}
