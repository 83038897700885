import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Country } from "src/models/Country/Country";
import { GlobalEnvironmentProvider } from "./global-service";

@Injectable()
export class CountryServiceProvider {
  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getAllCountries() {
    let url = this.apiUrl + 'country/GetCountries';
    return this.http.get(url);
  }

  getCountry(codeCountry: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'country/GetCountry?code=' +codeCountry;
    return this.http.get<Country>(url, {headers: headers});
  }
  checkCountry(name: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'country/verifCountry?countryName=' + name;
    return this.http.get(url, {headers: headers});
  }
}
