import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomerFinalRecommender } from 'src/models/customerFinalRecommender/customerFinalRecommender';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { CustomerFinalRecommenderServiceProvider } from 'src/providers/customer-final-recommender-service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-forget-email',
  templateUrl: './forget-email.component.html',
  styleUrls: ['./forget-email.component.scss']
})
export class ForgetEmailComponent implements OnInit {
  public step: number;
  public emailCustomer: string = '';
  public isActiveP;
  private customerFinalRecommender: CustomerFinalRecommender = null;
  private customerFinalRecommenderId: string = null;

  constructor(
    public dialogRef: MatDialogRef<ForgetEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customer_final_recommender_service: CustomerFinalRecommenderServiceProvider,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.customerFinalRecommenderId = this.data;
    this.step = 1;
  }

  //func1 Close the dialog
  onClose() {
    this.dialogRef.close(this.emailCustomer);
  }

  //func2 Open terms and conditions
  openTerms() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (window.innerWidth < 767) {
      dialogConfig.maxWidth = "80%";
    } else if (window.innerWidth < 1024) {
      dialogConfig.maxWidth = "80%";
    } else {
      dialogConfig.maxWidth = "900px";
    }
    this.dialog.open(TermsAndConditionsComponent, dialogConfig);
  }

  //func3 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func4 Update customer final recommender Email
  async send() {
    this.customerFinalRecommender = await this.customer_final_recommender_service.updateCustomerFinalRecommenderEmail(this.customerFinalRecommenderId, this.emailCustomer);
    sessionStorage.setItem('recommender', JSON.stringify(this.customerFinalRecommender));
    this.step = 2;
  }
}
