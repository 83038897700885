import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ToastrService } from 'ngx-toastr';
import { Base } from 'src/models/base/base';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { Line } from 'src/models/line/line';
import { Spf } from 'src/models/spf/spf';
import { Texture } from 'src/models/texture/texture';
import { BaseServiceProvider } from 'src/providers/base-service';
import { BaseInfoSt2Component } from '../base-info-st2/base-info-st2.component';
import { Globals } from '../globals';

@Component({
  selector: 'app-base-st2',
  templateUrl: './base-st2.component.html',
  styleUrls: ['./base-st2.component.scss']
})
export class BaseSt2Component implements OnInit {
  public allBases: Base[] = [];
  public basesFiltered: Base[] = [];
  public basesFilteredG: Base[] = [];
  public baseSelected: Base;
  public allTextures: Texture[] = [];
  public allSPF: Spf[] = [];
  public allLine: Line[] = [];
  public texturesFiltered: Texture[] = [];
  public lineFiltered: Line[] = [];
  public spfFiltered: Spf[] = [];
  public textureSelected: Texture;
  public spfSelected: Spf;
  public lineSelected: Line;
  linesType: number[];
  spfTypes: number[];
  textureTypes: number[];
  public canIGoNext: boolean = false;
  imgSrc: string = "assets/imgs/btn-info-lesielleB.svg";
  public textureReady = false;
  public lineReady = false;
  public spfReady = false;

  constructor(
    private baseService: BaseServiceProvider,
    private router: Router,
    private translate: TranslateService,
    private zone: NgZone,
    private toastr: ToastrService,
    private globals: Globals,
    private dialog: MatDialog,
    public navCtrl: NgxNavigationWithDataComponent,
  ) {
  }

  ngOnInit() {
    this.globals.nav2Per = 0;
    this.globals.nav1Per = 66;
    this.filterBasesFromBaseSelected();
    this.getAllTexture();
    this.getAllLine();
    this.getAllSPF();

  }

  //Change icon on mouse over
  onMouseOver(): void {
    this.imgSrc = "assets/imgs/btn-info-lesielleW.svg";
  }

  //Change icon on mouse out
  onMouseOut(): void {
    this.imgSrc = "assets/imgs/btn-info-lesielleB.svg";
  }

  //func1 Block the next button to oblige the user to choose a combination
  updateCanIGoNextStep() {
    if (this.basesFiltered != undefined && this.basesFiltered.length == 1 &&
      (this.textureSelected != undefined && this.textureSelected.type != 0) &&
      (this.lineSelected != undefined && this.lineSelected.type != 0) &&
      this.spfSelected != undefined) {
      this.canIGoNext = true;
    }
  }

  //func2 Check if  this item is check or not && and check if am able to choose it and get the value (Texture)
  clickTexture(texture: Texture) {
    if (!texture.enabled || (this.textureTypes != undefined && this.textureTypes.indexOf(texture.type) == -1 && (this.lineSelected != undefined || this.spfSelected != undefined))) {
      this.showAlert();
    } else {
      this.zone.run(() => {
        if (this.textureSelected != undefined && texture.type == this.textureSelected.type) {
          this.textureSelected = undefined;
        } else {
          this.textureSelected = texture;
        }
        this.updateView();
      });
    }
  }

  //func3 Check if  this item is check or not && and check if am able to choose it and get the value (Line)
  clickLine(line: Line) {
    if (!line.enabled || this.linesType != undefined && this.linesType.indexOf(line.type) == -1 && (this.textureSelected != undefined || this.spfSelected != undefined)) {
      this.showAlert();
    } else {
      this.zone.run(() => {
        if (this.lineSelected != undefined && line.type == this.lineSelected.type) {
          this.lineSelected = undefined;
        } else {
          this.lineSelected = line;
        }
        this.updateView();
      });
    }
  }

  //func4 Check if  this item is check or not && and check if am able to choose it and get the value (SPF)
  clickSPF(spf: Spf) {
/*     if (this.spfSelected == spf) {
 */      if (/* !spf.enabled || */ this.spfTypes != undefined && this.spfTypes.indexOf(spf.type) == -1 && (this.lineSelected != undefined || this.textureSelected != undefined)) {
      this.showAlert();
    } else {
      this.zone.run(() => {
        if (this.spfSelected != undefined && spf.type == this.spfSelected.type) {
          this.spfSelected = undefined;
        } else {
          this.spfSelected = spf;
        }
        this.updateView();
      });
    }
  }

  //func5 Eliminate the combination that I can't choose it
  updateView() {
    this.basesFiltered = this.allBases.filter(p => p.type == this.baseSelected.type && p.sensitive == this.baseSelected.sensitive &&
      (this.textureSelected == undefined || p.texture.type == this.textureSelected.type) &&
      (this.lineSelected == undefined || p.line.type == this.lineSelected.type) &&
      (this.spfSelected == undefined || p.spf.type == this.spfSelected.type));
    this.linesType = this.basesFiltered.map(item => item.line.type).filter((value, index, self) => self.indexOf(value) === index);
    this.spfTypes = this.basesFiltered.map(item => item.spf.type).filter((value, index, self) => self.indexOf(value) === index);
    this.textureTypes = this.basesFiltered.map(item => item.texture.type).filter((value, index, self) => self.indexOf(value) === index);
    for (let index = 0; index < this.texturesFiltered.length; index++) {
      this.texturesFiltered[index].enabled = true;
      if (this.textureTypes.indexOf(this.texturesFiltered[index].type) == -1) {
        this.texturesFiltered[index].enabled = false;
        /* if (this.textureSelected == this.texturesFiltered[index]) {
          this.textureSelected = null
        } */
      }
    }
    for (let index = 0; index < this.lineFiltered.length; index++) {

      this.lineFiltered[index].enabled = true;
      if (this.linesType.indexOf(this.lineFiltered[index].type) == -1) {
        this.lineFiltered[index].enabled = false;
        if (this.lineSelected == this.lineFiltered[index]) {
          this.lineSelected = null
        }
      }
    }
    for (let index = 0; index < this.spfFiltered.length; index++) {

      this.spfFiltered[index].enabled = true;
      if (this.spfTypes.indexOf(this.spfFiltered[index].type) == -1) {
        this.spfFiltered[index].enabled = false;
        if (this.spfSelected == this.spfFiltered[index]) {
          this.spfSelected = null
        }
      }
    }
    this.updateCanIGoNextStep();
  }

  //func4' Check if  this item is check or not && and check if am able to choose it and get the value (SPF)
  clickSPF1(spf: Spf) {
    if (!spf.enabled) {
      if (this.basesFilteredG.filter(b => b.spf.type === spf.type).length == 0) {
        this.showAlert();
      } else {
        if (this.spfSelected != undefined && spf.type == this.spfSelected.type) {
          this.spfSelected = undefined;
        } else {
          this.spfSelected = spf;
        }
        this.updateView();
      }
    } else {
      if (this.spfSelected != undefined && spf.type == this.spfSelected.type) {
        this.spfSelected = undefined;
      } else {
        this.spfSelected = spf;
      }
      this.updateView();
    }
  }

  clickLine1(line: Line) {
    if (!line.enabled) {
      if (this.basesFilteredG.filter(b => b.line.type === line.type).length == 0) {
        this.showAlert();
      }
      else {
        if (this.lineSelected != undefined && line.type == this.lineSelected.type) {
          this.lineSelected = undefined;
        } else {
          this.lineSelected = line;
        }
        this.updateView();
      }
    } else {
      if (this.lineSelected != undefined && line.type == this.lineSelected.type) {
        this.lineSelected = undefined;
      } else {
        this.lineSelected = line;
      }
      this.updateView();
    }
  }

  clickTexture1(texture: Texture) {
    if (!texture.enabled) {
      if (this.basesFilteredG.filter(b => b.texture.type === texture.type).length == 0) {
        this.showAlert();
      } else {
        if (this.textureSelected != undefined && texture.type == this.textureSelected.type) {
          this.textureSelected = undefined;
        } else {
          this.textureSelected = texture;
        }
        this.updateView();
      }
    } else {
      if (this.textureSelected != undefined && texture.type == this.textureSelected.type) {
        this.textureSelected = undefined;
      } else {
        this.textureSelected = texture;
      }
      this.updateView();
    }
  }

  //func6 Navigate to the route /base-step3
  gosymptomSt1() {
    if (this.canIGoNext) {
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/active-step1', {
        baseSelected: this.basesFiltered[0],
        baseSelected0: this.navCtrl.get('baseSelected0'),
        clinicId: this.navCtrl.get('clinicId'),
        allBases: this.navCtrl.get('allBases')
      });
    }
  }

  filterBasesFromBaseSelected1() {
    this.baseSelected = this.navCtrl.get('baseSelected0');
    this.allBases = this.navCtrl.get('allBases');
    this.basesFilteredG = this.allBases.filter(p => p.type == this.baseSelected.type && p.sensitive == this.baseSelected.sensitive);
    this.basesFiltered = this.basesFilteredG;
  }

  //func7 Get the Combination possible after choosing the base type
  filterBasesFromBaseSelected() {
    this.baseSelected = this.navCtrl.get('baseSelected0');
    this.allBases = this.navCtrl.get('allBases');
    this.basesFiltered = this.allBases.filter(p => p.type == this.baseSelected.type && p.sensitive == this.baseSelected.sensitive);
  }

  //func8 Get all the texture from the data base and filter them according to the combination available
  getAllTexture() {
    this.baseService.getAllTextures()
      .subscribe(
        (data: [Texture]) => {
          for (let index = 0; index < data.length; index++) {
            if (this.basesFiltered.filter(p => p.texture.type == data[index].type).length > 0) {
              data[index].enabled = true;
            } else {
              data[index].enabled = false;
            }
            this.allTextures.push(data[index]);
          }
          this.texturesFiltered = this.allTextures;
          this.textureReady = true;
          if(this.textureReady && this.lineReady && this.spfReady){
            this.allReady();
          }
        },
        err => {
          console.log('error Textures');
          console.log(err);
          console.log(err.name == 'TimeoutError');
        }
      );
  }

  allReady(){
    if(localStorage.getItem("appCountry") == "tr" || localStorage.getItem("appCountry") == "us" || localStorage.getItem("appCountry") == "uk"){
      let spf = this.allSPF.find((e)=>e["_id"] == "5b9957c825f4e10162965555");
      this.clickSPF(spf);
      let texture = this.allTextures.find((e)=>e["_id"] == "5b914d357e6cce24f5374879")
      this.clickTexture(texture);
      let line = this.allLine.find((e)=>e["_id"] == "5b914d357e6cce24f5374879");
      this.clickLine(line);
      this.gosymptomSt1();
    }

  }

  //func9 Get all the line from the data base and filter them according to the combination available
  getAllLine() {
    this.baseService.getAllLines().subscribe(
      (data: [Line]) => {
        for (let index = 0; index < data.length; index++) {
          if (this.basesFiltered.filter(p => p.line.type == data[index].type).length > 0) {
            data[index].enabled = true;
          } else {
            data[index].enabled = false;
          }
          this.allLine.push(data[index]);
        }
        this.lineFiltered = data;
        this.lineReady = true;
          if(this.textureReady && this.lineReady && this.spfReady){
            this.allReady();
          }
      },
      err => {
        console.log('error Lines');
        console.log(err);
        console.log(err.name == 'TimeoutError');
      }
    );
  }

  //func10 Get all the SPF from the data base and filter them according to the combination available
  getAllSPF() {
    this.baseService.getAllSPf().subscribe(
      (data: [Spf]) => {
        for (let index = 0; index < data.length; index++) {

          if (this.basesFiltered.filter(p => p.spf.type == data[index].type).length > 0) {
            data[index].enabled = true;
          } else {
            data[index].enabled = false;
          }
          this.allSPF.push(data[index]);
        }
        this.spfFiltered = this.allSPF;
        this.spfReady = true;
          if(this.textureReady && this.lineReady && this.spfReady){
            this.allReady();
          }
      },
      err => {
        console.log('error SPF');
        console.log(err);
        console.log(err.name == 'TimeoutError');
      }
    );
  }

  //func11 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func12 Show the alert telling that this combination is not available
  showAlert() {
    let str1: string;
    let str2: string;
    this.translate.get('NO_DISPONIBLE').subscribe((res: string) => {
      str1 = res;
    });
    this.translate.get('NO_DISPONIBLE_DESC').subscribe((res: string) => {
      str2 = res;
    });
    this.toastr.error(str2, '', {
      timeOut: 2000,
      messageClass: 'text',
      positionClass: 'toast-bottom-right'
    });
  }

  //func13 Go back to the last page
  goback() {
    this.router.navigate(['/base-step1']);
  }

  //func14 Open the base information 2
  open() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    if (window.innerWidth < 767) {
      dialogConfig.maxWidth = "80%";
    } else if (window.innerWidth < 1024) {
      dialogConfig.maxWidth = "80%";
    } else {
      dialogConfig.maxWidth = "500px";
    }
    const dialogRef = this.dialog.open(BaseInfoSt2Component, dialogConfig);
  }
}
