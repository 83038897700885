import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Device } from 'src/models/Device/device';
import {GlobalEnvironmentProvider} from './global-service';

/*
  Generated class for the BaseServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class DeviceServiceProvider {
  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getAllDevice() {
    let url = this.apiUrl + 'device/GetDevices';
    return this.http.get(url);
  }

  getDevicebyId(deviceId: number) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'device/GetDevice?deviceId=' + deviceId;
    return this.http.get<Device>(url, {headers: headers}).toPromise();
  }
}
