import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base-info-st1',
  templateUrl: './base-info-st1.component.html',
  styleUrls: ['./base-info-st1.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class BaseInfoSt1Component implements OnInit {
  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
  }

  //func1 Open the modal
  open(content) {
    this.modalService.open(content);
  }

  //func2 Close the modal
  close() {
    this.modalService.dismissAll();
  }
}
