import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GlobalEnvironmentProvider} from './global-service';

/*
  Generated class for the AssetServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class QuestionBaseServiceProvider {

  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }


  getAllQuestionBase() {
    let url = this.apiUrl + 'questionBase/GetAllQuestionBase' ;
    return this.http.get(url);
  }

  getQuestionBasebyId(qBId:number) {
    let url = this.apiUrl + 'questionBase/GetQuestionBase?questionBaseId=' + qBId;
    return this.http.get(url);
  }
}
