import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base } from 'src/models/base/base';
import { GlobalEnvironmentProvider } from './global-service';

/*
  Generated class for the BaseServiceProvider provider.
  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class BaseServiceProvider {
  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getAllBases() {
    let url = this.apiUrl + 'base/GetBases';
    return this.http.get<Base[]>(url);
  }

  getBase(idBase: number) {
    let url = this.apiUrl + 'base/GetBase?baseId=' +idBase;
    return this.http.get<Base>(url).toPromise();
  }

  getSensitiveBase(sen: boolean, id: number) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'base/GetSensitiveBase?sen=' + sen + '&baseId=' + id;
    return this.http.get(url, { headers: headers });
  }

  getAllTextures() {
    let url = this.apiUrl + 'base/GetTextures';
    return this.http.get(url);
  }

  getAllLines() {
    let url = this.apiUrl + 'base/GetLines';
    return this.http.get(url);
  }

  getAllSPf() {
    let url = this.apiUrl + 'base/GetSpf';
    return this.http.get(url);
  }
}
