import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { TextIsoValues } from 'src/models/enum/textIsoValues';
import { QuestionBase } from 'src/models/question/questionBase';
import { QuestionBaseAnswer } from 'src/models/question/questionBaseAnswer';
import { Base } from '../../models/base/base';
import { QuestionBaseServiceProvider } from '../../providers/question-base-service';
import { BaseServiceProvider } from '../../providers/base-service';

@Component({
  selector: 'app-base-tuto-st1',
  templateUrl: './base-tuto-st1.component.html',
  styleUrls: ['./base-tuto-st1.component.scss']
})
export class BaseTutoSt1Component implements OnInit {
  public BaseQuestion1: QuestionBase;
  public BaseQuestion2: QuestionBase;
  public BaseQuestion3: QuestionBase;
  public result: boolean = false;
  public questionNumber: number = 1;
  public base: number = 0;
  public baseFinal: Base = null;
  public allBase: Base[] = [];
  public answerQues1 = false;

  public lang = localStorage.getItem('appLang');
  dataSource = new MatTableDataSource();

  constructor(
    private questionBaseService: QuestionBaseServiceProvider,
    private baseService: BaseServiceProvider,
    private spinner: NgxSpinnerService,
    private zone: NgZone,
    public dialogRef: MatDialogRef<BaseTutoSt1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.questionNumber = 1;
    this.getQuestion();
    this.getAllBase();
    this.dataSource.data.splice(this.dataSource.data.indexOf(this.data), 1);
    this.dataSource.data = [...this.dataSource.data];
  }

  //func1 Close dialog without save result
  onClose() {
    if (this.baseFinal != null) {
      this.dialogRef.close(this.baseFinal);
    } else {
      this.dialogRef.close();
    }
  }

  //func2 Select the answer of the first question
  selectAnswer1(answer: QuestionBaseAnswer) {
    this.answerQues1 = false;
    this.base = 0;
    this.baseFinal = null;
    this.zone.run(() => {
      if (answer.goQuestion2) {
        this.questionNumber = 2;
      } else if (answer.goQuestion3) {
        this.answerQues1 = true;
        this.questionNumber = 3;
      }
      this.base = answer.baseId;
    });
  }

  //func3 Select the answer of the second question
  selectAnswer2(answer: QuestionBaseAnswer) {
    this.zone.run(() => {
      if (answer.goQuestion3) {
        this.questionNumber = 3;
      }
      this.base = answer.baseId;
    });
  }

  //func4 Select the answer of the third question
  selectAnswer3(answer: QuestionBaseAnswer) {
    if (answer.internalId == 6) {
      this.baseService.getSensitiveBase(true, this.base).subscribe((data: Base) => {
        this.baseFinal = data;
        this.base = data.internalId;
        this.getBaseColor(this.base);
      });
    } else {
      this.baseService.getSensitiveBase(false, this.base).subscribe((data: Base) => {
        this.baseFinal = data;
        this.base = data.internalId;
        this.getBaseColor(this.base);
      });
    }
    this.getBaseColor(this.base);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.questionNumber = 0;
      this.result = true;
    }, 1000);
  }

  //func5 Go back to the last question
  back(qNbr: Number) {
    if (qNbr == 2) {
      this.questionNumber = 1;
    } else if (qNbr == 3) {
      if (this.answerQues1) {
        this.questionNumber = 1;
      } else {
        this.questionNumber = 2;
      }
    }
  }

  //func6 Above result and go back
  backResult(r: Boolean) {
    if (r == true) {
      this.result = false;
      this.questionNumber = 3
    }
  }

  //func7 Save result and close the popup
  selectResult() {
    this.dialogRef.close(this.baseFinal);
  }

  //func8 Get base tutorial questions
  getQuestion() {
    this.questionBaseService.getAllQuestionBase()
      .subscribe(
        (data: QuestionBase[]) => {

          this.BaseQuestion1 = data[0];
          this.BaseQuestion2 = data[1];
          this.BaseQuestion3 = data[2];
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //func9 Get Lesielle bases
  getAllBase() {
    this.baseService.getAllBases()
      .subscribe(
        (data: Base[]) => {
          this.allBase = data;
          //this.loader = false;
        },
        err => {
          console.log('error bases');
          console.log(err);
          console.log(err.name == 'TimeoutError');
        }
      );
  }

  //func10 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func11 Get the specific color for our bases
  getBaseColor(baseId: number) {
    let classB = "";
    switch (baseId) {
      case 13: case 14: case 15: case 16:
        classB = "base1";
        break;
      case 9: case 10: case 12: case 24:
        classB = "base2";
        break;
      case 5: case 6: case 7: case 8: case 28:
        classB = "base3";
        break;
      case 1: case 2: case 3: case 4: case 25:
        classB = "base4";
        break;
      case 26: case 27:
        classB = "base5";
        break;
      case 22: case 23:
        classB = "base6";
        break;
      case 20: case 21:
        classB = "base7";
        break;
      case 18: case 19:
        classB = "base8";
        break;
    }
    return classB;
  }

  //func12 Get the translate name of the item with type TextIsoValues
  getBenifits(lst: TextIsoValues[]): string[] {
    //let lang = 'en';
    if (lst == null) {
      return null;
    }
    let finded = lst.filter(p => p.iso == this.lang)[0];
    if (finded == null) {
      return null;
    }
    return finded.value;
  }
}
