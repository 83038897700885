import { Base } from 'src/models/base/base';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalEnvironmentProvider } from './global-service';
import { CustomerFinalRecommender } from '../models/customerFinalRecommender/customerFinalRecommender';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CustomerFinalRecommenderServiceProvider {
  private apiUrl: string;
  public email: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getCustomerFinalRecommenderByCustomer(userId: string) {
    let url = this.apiUrl + 'CustomerFinalRecommender/GetCustomerFinalRecommenderByCustomer?u=' + userId;
    return this.http.get(url);
  }

  getCustomerFinalRecommenderById(id: string) {
    let url = this.apiUrl + 'CustomerFinalRecommender/GetCustomerFinalRecommender?id=' + id;
    return this.http.get(url);
  }

  sendFullRecomendation(userId: string, language: string, baseId: number, newbaseId: number, allergies: string, sypmtoms: number[], questions: number[], clinicEmail: string, urlSelling: string, lang: string, retinol: number) {
    var headers = this.globalEnvironment.GetHeaderWithBearerAndNoCache();
    let url = this.apiUrl + 'CustomerFinalRecommender/SendFullRecomendation?u=' + userId + '&l=' + language + '&b=' + baseId + '&nb=' + newbaseId + '&al=' + allergies + '&s=' + sypmtoms.join(';') + '&q=' + questions.join(';') + '&cm=' + clinicEmail + '&url=' + urlSelling + '&lang=' + lang + '&retinol=' + retinol;
    return this.http.post(url, null, headers);
  }
  ExistRetinolActive(baseId: number, sypmtoms: number[], questions: number[]) {
    let url = this.apiUrl + 'CustomerFinalRecommender/ExistRetinolActive?b=' + baseId + '&s=' + sypmtoms.join(';') + '&q=' + questions.join(';');
    return this.http.get(url);
  }

  addCustomerFinalRecommender(user: string, base: number, newbaseId: number, allergies: number[], sypmtoms: number[], questions: number[], clinicId: string, deviceId: number, urlSelling: string, lang: string, retinol: number) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'CustomerFinalRecommender/addCustomerFinalRecommender?u=' + user + '&b=' + base + '&nb=' + newbaseId + '&al=' + allergies.join(';') + '&s=' + sypmtoms.join(';') + '&q=' + questions.join(';') + '&c=' + clinicId + '&d=' + deviceId + '&url=' + urlSelling + '&lang=' + lang + '&retinol=' + retinol;
    return this.http.post<CustomerFinalRecommender>(url, null, { headers: headers });
  };

  updateCustomerFinalRecommenderEmail(id: string, email: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'CustomerFinalRecommender/updateCustomerFinalRecommenderEmail?id=' + id + '&email=' + email;
    return this.http.post<CustomerFinalRecommender>(url, null, { headers: headers }).toPromise();
  };
  updateCustomerFinalRecommenderDevice(id: string, idDevice: number) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'CustomerFinalRecommender/updateCustomerFinalRecommenderDevice?id=' + id + '&deviceId=' + idDevice;
    return this.http.post<CustomerFinalRecommender>(url, null, { headers: headers }).toPromise();
  };
}
