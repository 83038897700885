import { Component, Input, OnInit } from '@angular/core';
import { Advice } from 'src/models/Advice/advice';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { TextIsoValueWithTitle } from 'src/models/enum/textIsoValueWithTitle';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { AssetServiceProvider } from 'src/providers/asset-service';

@Component({
  selector: 'app-recommender-info2',
  templateUrl: './recommender-info2.component.html',
  styleUrls: ['./recommender-info2.component.scss']
})
export class RecommenderInfo2Component implements OnInit {
  public questionAnswers: QuestionAnswer[] = [];
  @Input() recommender: number;
  public advicesL1: Advice[] = [];
  public advicesL2: Advice[] = [];
  public advicesL3: Advice[] = [];


  constructor(
    private assetService: AssetServiceProvider,
  ) {
  }

  ngOnInit() {
    this.assetService.getAdvicesByRecommender(this.recommender).subscribe((data: Advice[]) => {
      for (let index = 0; index < data.length; index++) {
        if (index < 3) {
          this.advicesL1.push(data[index]);
        } else if (index >= 3 && index < 6) {
          this.advicesL2.push(data[index])
        } else if (index >= 6) {
          this.advicesL3.push(data[index])
        }
      }
    });
  }

  //func1 Get the translate name of the item with type TextIsoValueWithTitle
  getTitle(lst: TextIsoValueWithTitle[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.title;
  }

  //func2 Get the translate name of the item with type TextIsoValue
  getDesc(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }
}
