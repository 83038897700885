import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

/*
  Generated class for the GlobalEnvironmentProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalEnvironmentProvider {
  public apiServerUrl: 'http://localhost:5000/api/';
  public apiServerUrl2: 'http://ec2-54-174-44-120.compute-1.amazonaws.com/api/';
  public apiServerUrl3: 'https://api.lesielle.com/api/';
  public apiServerUrl4: 'http://63.32.54.14/api/';

  constructor(public http: HttpClient) {
    //console.log('Hello GlobalEnvironmentProvider Provider');
  }

  GetApiServerUrl(): string {
    return 'https://api.lesielle.com/api/';
  }

  GetHeaderWithBearerAndNoCache(): object {

    let token = localStorage.getItem('userToken');
    var headerLocal = {
      'Authorization': 'Bearer ' + token,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Access-Control-Allow-Origin':'*'
    };
    var headers = {headers: new HttpHeaders(headerLocal)};
    return headers;
  }
}
