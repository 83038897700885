import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AddDeviceGuardService } from './add-device/add-device-guard.service';
import { AddDeviceComponent } from './add-device/add-device.component';
import { AllergiesGuardService } from './allergies/allergies-guard.service';
import { AllergiesComponent } from './allergies/allergies.component';
import { BaseSt1GuardService } from './base-st1/base-st1-guard.service';
import { BaseSt1Component } from './base-st1/base-st1.component';
import { BaseSt2GuardService } from './base-st2/base-st2-guard.service';
import { BaseSt2Component } from './base-st2/base-st2.component';
import { ConfirmationEmailGuardService } from './confirmation-email/confirmation-email-guard.service';
import { ConfirmationEmailComponent } from './confirmation-email/confirmation-email.component';
import { FinalRecommendationGuardService } from './final-recommendation/final-recommendation-guard.service';
import { FinalRecommendationComponent } from './final-recommendation/final-recommendation.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecommenderInfo1Component } from './recommender-info1/recommender-info1.component';
import { RecommenderInfo2Component } from './recommender-info2/recommender-info2.component';
import { SymptomSt1GuardService } from './symptom-st1/symptom-st1-guard.service';
import { SymptomSt1Component } from './symptom-st1/symptom-st1.component';
import { SymptomSt2GuardService } from './symptom-st2/symptom-st2-guard.service';
import { SymptomSt2Component } from './symptom-st2/symptom-st2.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WelcomeRecommenderComponent } from './welcome-recommender/welcome-recommender.component';

const indexRoute: Route = {
  path: '',
  component: WelcomeRecommenderComponent
};
const fallBackRoute: Route = {
  path: '**',
  component: PageNotFoundComponent
};
const routes: Routes = [
  { path: 'clinic/:clinicname', component: WelcomeRecommenderComponent },
  /* { path: 'int/:countryN', component: WelcomeRecommenderComponent }, */
  { path: '', component: WelcomeRecommenderComponent },
  { path: ':country/:lang', component: WelcomeRecommenderComponent },
  {
    path: 'base-step1', component: BaseSt1Component,
    canActivate: [BaseSt1GuardService]
  },
  {
    path: ':country/:lang/base-step1', component: BaseSt1Component,
    canActivate: [BaseSt1GuardService]
  },
  {
    path: 'base-step2', component: BaseSt2Component,
    canActivate: [BaseSt2GuardService]
  },
  {
    path: ':country/:lang/base-step2', component: BaseSt2Component,
    canActivate: [BaseSt2GuardService]
  },
  {
    path: 'allergies', component: AllergiesComponent,
    canActivate: [AllergiesGuardService]
  },
  {
    path: ':country/:lang/allergies', component: AllergiesComponent,
    canActivate: [AllergiesGuardService]
  },
  {
    path: 'active-step1', component: SymptomSt1Component,
    canActivate: [SymptomSt1GuardService]
  },
  {
    path: ':country/:lang/active-step1', component: SymptomSt1Component,
    canActivate: [SymptomSt1GuardService]
  },
  {
    path: 'active-step2', component: SymptomSt2Component,
    canActivate: [SymptomSt2GuardService]
  },
  {
    path: ':country/:lang/active-step2', component: SymptomSt2Component,
    canActivate: [SymptomSt2GuardService]
  },
  { path: 'active-step2-info1', component: RecommenderInfo1Component },
  { path: ':country/:lang/active-step2-info1', component: RecommenderInfo1Component },
  { path: 'active-step2-info2', component: RecommenderInfo2Component },
  { path: ':country/:lang/active-step2-info2', component: RecommenderInfo2Component },
  {
    path: 'final-recommender', component: FinalRecommendationComponent,
    canActivate: [FinalRecommendationGuardService]
  },
  {
    path: ':country/:lang/final-recommender', component: FinalRecommendationComponent,
    canActivate: [FinalRecommendationGuardService]
  },
  {
    path: 'device', component: AddDeviceComponent,
    canActivate: [AddDeviceGuardService]
  },
  {
    path: ':country/:lang/device', component: AddDeviceComponent,
    canActivate: [AddDeviceGuardService]
  },
  {
    path: 'registration', component: ConfirmationEmailComponent,
    canActivate: [ConfirmationEmailGuardService]
  },
  {
    path: ':country/:lang/registration', component: ConfirmationEmailComponent,
    canActivate: [ConfirmationEmailGuardService]
  },
  { path: '404', component: PageNotFoundComponent },
  { path: 'Our_Terms_and_Conditions', component: TermsAndConditionsComponent },
  { path: '**', redirectTo: '404' },
  indexRoute,
  fallBackRoute,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // This option for opening the page from the top on reload
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
