import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class BaseSt1GuardService implements CanActivate {
  constructor(
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('appLang')) {
      return true;
    } else {
      this.router.navigate(['/' + localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang")]);
    }
  }
}
