import { element } from 'protractor';
import { Component, NgZone, OnInit } from '@angular/core';
import { Question } from 'src/models/question/question';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { Base } from 'src/models/base/base';
import { Symptom } from 'src/models/symptom/symtom';
import { SymptomServiceProvider } from 'src/providers/symptom-service';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Active } from 'src/models/Active/active';
import { RosaceaPopupComponent } from '../rosacea-popup/rosacea-popup.component';
import { RecommenderInfo1Component } from '../recommender-info1/recommender-info1.component';
import { Globals } from '../globals';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-symptom-st2',
  templateUrl: './symptom-st2.component.html',
  styleUrls: ['./symptom-st2.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class SymptomSt2Component implements OnInit {
  public allQuestions: Question[] = [];
  public pregnantQuestion: Question[] = [];
  public areYouPregnant: Question[] = [];
  public questionAnswers: QuestionAnswer[] = [];
  public itemSelected: QuestionAnswer = null;
  private baseSelected: Base;
  private symptomsSelected: Symptom[];
  private haveAllergyQuestion: QuestionAnswer;
  private showMsgSensitiveRosacea = false;
  private haveSensitiveBase: Boolean = false;
  public allergySelected: Active[] = [];
  public areWoman: Boolean = false;
  public questionNum: number = 0;


  constructor(
    public symptomService: SymptomServiceProvider,
    private zone: NgZone,
    private router: Router,
    private globals: Globals,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastr: ToastrService,
    private navCtrl: NgxNavigationWithDataComponent
  ) {
  }

  ngOnInit() {
    this.globals.nav1Per = 100;
    this.globals.nav2Per = 66;
    this.globals.nav3Per = 0;
    this.baseSelected = this.navCtrl.get('baseSelected');
    this.symptomsSelected = this.navCtrl.get('symptomsSelected');
    this.getQuestion();
  }

  //func1 Get the answers appropriate to the questions
  selectAnswer(answer: QuestionAnswer, i: number) {
    this.zone.run(() => {
      let found = this.questionAnswers.filter(p => p.questionId == answer.questionId);
      if (found != null && found != undefined && found[0] != null && found[0] != undefined) {
        this.questionAnswers = this.questionAnswers.filter(p => p.questionId != answer.questionId);
      }
      this.itemSelected = answer;
      this.questionAnswers.push(answer);
      var element = document.getElementById("element" + i);
      element.scrollIntoView({ behavior: "smooth", block: 'start' });
    });
  }

  selectAnswerPregnant(answer: QuestionAnswer, i: number) {
    this.zone.run(() => {
      let found = this.questionAnswers.filter(p => p.questionId == answer.questionId);
      if (found != null && found != undefined && found[0] != null && found[0] != undefined) {
        this.questionAnswers = this.questionAnswers.filter(p => p.questionId != answer.questionId);
      }
      this.itemSelected = answer;
      this.questionAnswers.push(answer);
      if (answer.internalId == 21 || answer.internalId == 23) {
        this.areWoman = true;
      } else {
        this.areWoman = false;
        this.questionAnswers = this.questionAnswers.filter(item => !(item.questionId > 10))
      }
      var element = document.getElementById("element" + i);
      element.scrollIntoView({ behavior: "smooth", block: 'start' });
    });
  }

  //func2 Get all the symptom questions
  getQuestion() {
    this.symptomService.getAllSymptomsQuestions(this.baseSelected.internalId, this.symptomsSelected.map(function (a) {
      return a.internalId;
    })).subscribe(
      (data: Question[]) => {
        this.allQuestions = data;
        //this.allQuestions = data.filter(q => q.internalId != 9 && q.internalId != 10 && q.internalId != 12);
        this.pregnantQuestion = data.filter(q => q.internalId == 9 || q.internalId == 10);
        this.areYouPregnant = data.filter(q => q.internalId == 12);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //func3 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func4 Get the question answer selected to change the class
  getSelectedClass(item: QuestionAnswer): string {
    let str = this.questionAnswers.filter(p => p.internalId == item.internalId) != undefined &&
      this.questionAnswers.filter(p => p.internalId == item.internalId)[0] != undefined ? 'selected' : '';
    console.log(str);
    return str;
  }

  //func5 Check if the item is checked or not
  isSelected(item: QuestionAnswer): boolean {
    let obj = this.questionAnswers.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func6 Go back the last page
  goback() {
    this.router.navigate(['/active-step1']);
  }

  //func7 Go to the allergies page
  goAllergies() {
    if (this.areWoman) {
      if (this.allQuestions.length != this.questionAnswers.length) {
        this.showAlert();
      } else {
        this.haveAllergyQuestion = this.questionAnswers.filter(p => p.questionId == 9)[0];
        if (this.haveAllergyQuestion.defaultName == "No") {
          this.manageGoModalsInfoOrRecomendationPage();
        }
        else {
          this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/allergies', {
            baseSelected: this.navCtrl.get('baseSelected'),
            symptomsSelected: this.navCtrl.get('symptomsSelected'),
            newBases: this.navCtrl.get('newBases'),
            allBases: this.navCtrl.get('allBases'),
            clinicId: this.navCtrl.get('clinicId'),
            questionAnswer: this.questionAnswers
          });
        }
      }
    } else {
      if (this.allQuestions.length - 2 != this.questionAnswers.length) {
        this.showAlert();
      } else {
        this.haveAllergyQuestion = this.questionAnswers.filter(p => p.questionId == 9)[0];
        if (this.haveAllergyQuestion.defaultName == "No") {
          this.manageGoModalsInfoOrRecomendationPage();
        }
        else {
          this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/allergies', {
            baseSelected: this.navCtrl.get('baseSelected'),
            symptomsSelected: this.navCtrl.get('symptomsSelected'),
            newBases: this.navCtrl.get('newBases'),
            allBases: this.navCtrl.get('allBases'),
            clinicId: this.navCtrl.get('clinicId'),
            questionAnswer: this.questionAnswers
          });
        }
      }
    }

  }

  //func8 Control the modal order to be shown
  manageGoModalsInfoOrRecomendationPage() {
    let sen = this.questionAnswers.filter(p => p.shouldUseSensitive);
    let showMsgSensitive = sen != null && sen.length > 0 && sen[0].shouldUseSensitive == true && !this.baseSelected.sensitive;
    if (this.baseSelected.spf.type != null) {
      if (this.symptomsSelected.find(e => e.internalId == 2) != null) {
        this.showMsgSensitiveRosacea = true;
      }
    }
    this.haveSensitiveBase = this.baseSelected.sensitive;
    let showMsgSpf = sen != null && sen.length > 0 && sen[0].shouldUseSpf == true;
    if (!this.haveSensitiveBase && this.showMsgSensitiveRosacea) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      if (window.innerWidth < 767) {
        dialogConfig.maxWidth = "80%";
      } else if (window.innerWidth < 1024) {
        dialogConfig.maxWidth = "80%";
      } else {
        dialogConfig.maxWidth = "900px";
      }
      dialogConfig.data = {
        questionAnswer: this.questionAnswers
      };
      const dialogRef = this.dialog.open(RosaceaPopupComponent, dialogConfig);
    } else if (showMsgSensitive || showMsgSpf) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      if (window.innerWidth < 767) {
        dialogConfig.maxWidth = "80%";
      } else if (window.innerWidth < 1024) {
        dialogConfig.maxWidth = "80%";
      } else {
        dialogConfig.maxWidth = "900px";
      }
      dialogConfig.data = {
        questionAnswer: this.questionAnswers
      };
      const dialogRef = this.dialog.open(RecommenderInfo1Component, dialogConfig);
    } else {
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
        baseSelected: this.navCtrl.get('baseSelected'),
        symptomsSelected: this.navCtrl.get('symptomsSelected'),
        questionAnswer: this.questionAnswers,
        newBases: this.navCtrl.get('newBases'),
        clinicId: this.navCtrl.get('clinicId'),
        finish: 'yes'
      });
    }
  }

  //func9 Show the alert telling that this combination is not available
  showAlert() {
    let str1: string;
    let str2: string;
    this.translate.get('ANSWER_QUESTION').subscribe((res: string) => {
      str1 = res;
    });
    this.translate.get('ANSWER_QUESTION').subscribe((res: string) => {
      str2 = res;
    });
    this.toastr.error(str2, '', {
      timeOut: 2000,
      messageClass: 'text',
      positionClass: 'toast-bottom-right'
    });
  }
}
