import { Injectable } from '@angular/core';

@Injectable()
export class Globals {

  /* navExist: boolean = false;
  nav1Per: number = 30;
  nav2Per: number = 0;
  nav3Per: number = 0; */
  role: string = 'test';
  navE: boolean = false;
  lastScreen: boolean = false;
  nav1Per: number =0;
  nav2Per: number =0;
  nav3Per: number =0;

}
