import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Active } from 'src/models/Active/active';
import { Base } from 'src/models/base/base';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { SymptomAssetResult } from 'src/models/symptom/symptomAssetResult';
import { Symptom } from 'src/models/symptom/symtom';
import { AssetServiceProvider } from 'src/providers/asset-service';
import { Globals } from '../globals';
import { NormalBasePopupComponent } from '../normal-base-popup/normal-base-popup.component';
import { RecommenderInfo1Component } from '../recommender-info1/recommender-info1.component';
import { RosaceaPopupComponent } from '../rosacea-popup/rosacea-popup.component';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss']
})
export class AllergiesComponent implements OnInit {
  public questionAnswers: QuestionAnswer[] = [];
  public allAllergies: Active[] = [];
  public otherAllergie: Active[] = [];
  private baseSelected: Base;
  public allergySelected: Active[] = [];
  public allergiesSelected: number[] = [];
  public nbrNewBase: number = 0;
  public allergiesChecked: number = 0;
  public haveAllergie = false;
  private symptomsSelected: Symptom[];
  private showMsgSensitiveRosacea = false;
  private haveSensitiveBase: Boolean = false;
  public haveBisabolOrAloeVAllergy: Boolean = false;

  public newBases: Base[] = [];
  private allBases: Base[] = [];
  public baseNew = 0;
  public isActiveP;
  public emailCustomer: string = '';
  public lstResultSymptoms: SymptomAssetResult[];
  public urlCart: string;
  public allergieIds: number[];
  public allergiesStr: string = "";
  public device: number;
  public haveRetinol: string;


  public lang = localStorage.getItem('appLang');

  constructor(
    public assetService: AssetServiceProvider,
    private router: Router,
    private globals: Globals,
    private dialog: MatDialog,
    private navCtrl: NgxNavigationWithDataComponent
  ) {
  }

  ngOnInit() {
    this.globals.nav1Per = 100;
    this.globals.nav2Per = 90;
    this.globals.nav3Per = 0;
    this.allBases = this.navCtrl.get('allBases');
    this.questionAnswers = this.navCtrl.get('questionAnswer');
    this.baseSelected = this.navCtrl.get('baseSelected');
    this.symptomsSelected = this.navCtrl.get('symptomsSelected');
    this.assetService.getAllAssets().subscribe(
      (data: Active[]) => {
        this.allAllergies = data.filter(p => p.internalId >= 1 && p.internalId <= 18);
        this.otherAllergie = data.filter(p => p.internalId == 19);
      });
  }

  //func1: Return if the Item is checked or not
  isChecked(item: Active): boolean {
    let obj = this.allergiesSelected.filter(p => p == item.internalId);
    if (obj != undefined && obj[0] != undefined) {
      return true;
    }
    return false;
  }

  //func2: Get the item checked and send them to the local storage "allergiesSelected"
  clickAllergy(item: Active) {
    let obj = this.allergiesSelected.filter(p => p == item.internalId);
    if (obj != undefined && obj[0] != undefined) {
      this.allergiesSelected = this.allergiesSelected.filter(p => p != item.internalId);
      if (item.internalId == 18 || item.internalId == 19)
        this.allergiesChecked -= 2;
      else
        this.allergiesChecked -= 1;
      this.haveAllergie = false;
      this.allergySelected.splice(this.allergySelected.indexOf(item), 1);
    } else if (item.internalId == 19) {
      item.internalId = 18;
      this.allergiesSelected.push(item.internalId);
      this.allergySelected.push(item);
      this.allergiesChecked += 1;
    } else {
      this.allergiesSelected.push(item.internalId);
      this.allergySelected.push(item);
      this.allergiesChecked += 1;
      this.haveAllergie = true;
    }
  }

  //func3 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func4 Navigate to the route /active-step1
  goSypmtomst1() {
    this.router.navigate(['/active-step1']);
  }

  //func5 Control the modal order to be shown
  manageGoModalsInfoOrRecomendationPage() {
    let sen = this.questionAnswers.filter(p => p.shouldUseSensitive);
    let showMsgSensitive = sen != null && sen.length > 0 && sen[0].shouldUseSensitive == true && !this.baseSelected.sensitive;
    if (this.baseSelected.spf.type != null) {
      if (this.symptomsSelected.find(e => e.internalId == 2) != null) {
        this.showMsgSensitiveRosacea = true;
      }
    }
    let arrAV = [1, 2, 3, 5, 6, 8, 28];
    let arrB = [1, 2, 3, 4, 5, 6, 8, 28, 9, 10, 12, 13, 14, 16, 26];

    let basesFiltred = this.allBases.filter(b => arrAV.includes(b.internalId));
    this.haveSensitiveBase = this.baseSelected.sensitive;

    if (this.allergySelected != null) {
      if (this.allergySelected.some(e => e.internalId == 12)) {
        if (!arrAV.some(b => b == this.baseSelected.internalId)) {
          this.haveBisabolOrAloeVAllergy = true;
        }
      } else if (this.allergySelected.some(e => e.internalId == 17)) {
        if (!arrB.some(b => b == this.baseSelected.internalId)) {
          this.haveBisabolOrAloeVAllergy = true;
        }
      }
    }
    let showMsgSpf = sen != null && sen.length > 0 && sen[0].shouldUseSpf == true;
    if (this.haveBisabolOrAloeVAllergy) {
      let newNormalBase: Base[] = [];
      newNormalBase = this.allergyAlgorithm(this.baseSelected, basesFiltred);
      if (this.navCtrl.get('newBases') != null) {
        let newBase = this.navCtrl.get('newBases');
        this.allergyAlgorithm(newBase[0], basesFiltred).forEach(e => newNormalBase.push(e));
      }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      if (window.innerWidth < 767) {
        dialogConfig.maxWidth = "80%";
      } else if (window.innerWidth < 1024) {
        dialogConfig.maxWidth = "80%";
      } else {
        dialogConfig.maxWidth = "900px";
      }
      dialogConfig.data = {
        allergies: this.allergySelected,
        newNormalBase: newNormalBase
      };
      this.dialog.open(NormalBasePopupComponent, dialogConfig);
    } else if (!this.haveSensitiveBase && this.showMsgSensitiveRosacea) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      if (window.innerWidth < 767) {
        dialogConfig.maxWidth = "80%";
      } else if (window.innerWidth < 1024) {
        dialogConfig.maxWidth = "80%";
      } else {
        dialogConfig.maxWidth = "900px";
      }
      dialogConfig.data = {
        allergies: this.allergySelected,
      };
      this.dialog.open(RosaceaPopupComponent, dialogConfig);
    } else if (showMsgSensitive || showMsgSpf) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      if (window.innerWidth < 767) {
        dialogConfig.maxWidth = "80%";
      } else if (window.innerWidth < 1024) {
        dialogConfig.maxWidth = "80%";
      } else {
        dialogConfig.maxWidth = "900px";
      }
      dialogConfig.data = {
        allergies: this.allergySelected,
      };
      this.dialog.open(RecommenderInfo1Component, dialogConfig);
    } else {
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
        baseSelected: this.navCtrl.get('baseSelected'),
        symptomsSelected: this.navCtrl.get('symptomsSelected'),
        questionAnswer: this.navCtrl.get('questionAnswer'),
        newBases: this.navCtrl.get('newBases'),
        allBases: this.navCtrl.get('allBases'),
        clinicId: this.navCtrl.get('clinicId'),
        allergies: this.allergySelected,
        finish: 'yes'
      });
    }
  }

  //func6 check base disponibility with allergies selected
  allergyAlgorithm(base: Base, lstBase: Base[]) {
    //let newNormalBase: Base[] = [];
    if (base.type == 1) {
      lstBase = lstBase.filter(b => b.type == 1);
    } else {
      lstBase = lstBase.filter(b => b.type == 2);
    }
    lstBase = lstBase.filter(b => b.spf.type == base.spf.type);
    if (lstBase.length > 1) {
      lstBase = lstBase.filter(b => b.line.type == base.line.type);
      if (lstBase.length > 1) {
        lstBase = lstBase.filter(b => b.texture.type == base.texture.type);
        return (lstBase);
      } else {
        return (lstBase);
      }
    }
    return (lstBase);
  }

  //func7 Go back to /active-step2
  goback() {
    this.router.navigate(['/active-step2']);
  }
}
