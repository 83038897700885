import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ToastrService } from 'ngx-toastr';
import { Active } from 'src/models/Active/active';
import { Base } from 'src/models/base/base';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { BaseServiceProvider } from 'src/providers/base-service';

@Component({
  selector: 'app-recommender-info1',
  templateUrl: './recommender-info1.component.html',
  styleUrls: ['./recommender-info1.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class RecommenderInfo1Component implements OnInit {
  public questionAnswers: QuestionAnswer[] = [];
  public questionsAnswer: QuestionAnswer[] = [];
  public showMsgSpf: boolean = false;
  public showMsgSensitive: boolean = false;
  public nbrNewBase: number = 0;
  public allBases: Base[];
  public basesFiltred: Base[];
  public baseFiltredExact: Base[];
  public newSensitiveBase: Base[] = [];
  public selectedBases: Base[] = [];
  public sensitiveBase: Base;
  public itemSelected: Base = null;
  public rosaceaExist: boolean = false;
  public extraBase: boolean = false;
  public obj: boolean = false;
  public newBases: Base[] = [];
  public baseSelected: Base;
  public allergies: Active[] = [];
  public questionAnswer: QuestionAnswer[] = [];
  public lang = localStorage.getItem('appLang');

  constructor(
    private zone: NgZone,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private baseService: BaseServiceProvider,
    private translate: TranslateService,
    private navCtrl: NgxNavigationWithDataComponent,
    public dialogRef: MatDialogRef<RecommenderInfo1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.allergies = this.data.allergies;
    this.questionAnswer = this.data.questionAnswer;
    let baseFinal: Base = this.navCtrl.get('baseSelected');
    //this.symptomsSelected = this.navCtrl.get('symptomsSelected');
    if (this.navCtrl.get('questionAnswer') == null) {
      this.questionsAnswer = this.questionAnswer;
    } else {
      this.questionsAnswer = this.navCtrl.get('questionAnswer');
    }
    this.questionAnswers = this.questionsAnswer;
    let sen = this.questionAnswers.filter(p => p.shouldUseSensitive);
    this.showMsgSensitive = sen != null && sen.length > 0 && sen[0].shouldUseSensitive == true && !baseFinal.sensitive;
    this.showMsgSpf = sen != null && sen.length > 0 && sen[0].shouldUseSpf == true && !baseFinal.spf == undefined || baseFinal.spf.id == 0;
    this.questionAnswers = this.questionAnswers.filter(p => p.extraInfo != undefined && p.extraInfo.length > 0);
    this.getSensitiveBase();
  }

  //func1
  getSensitiveBase() {
    let newBases = this.navCtrl.get("newBases");
    let baseFinal: Base = this.navCtrl.get('baseSelected');
    this.baseService.getAllBases().subscribe((data: Base[]) => {
      this.allBases = data;
      this.basesFiltred = this.allBases.filter(b => b.type == baseFinal.type && b.sensitive == true);
      if (newBases != null) {
        this.extraBase = true;
        return this.newSensitiveBase = this.basesFiltred;
      } else {
        this.baseFiltredExact = this.basesFiltred.filter(a => a.texture.type == baseFinal.texture.type && a.line.type == baseFinal.line.type && a.spf.type == baseFinal.spf.type);
        if (this.baseFiltredExact.length!=0) {
          return this.newSensitiveBase = this.baseFiltredExact;
        } else {
          return this.newSensitiveBase = this.basesFiltred;
        }
      }
    });
  }

  //func2 Get the translate name of the item with type TextIsoValue
  getBaseName(lst: TextIsoValue[], defaultName: string): string {
    let finded = lst.filter(p => p.iso == this.lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func3
  isSelected(item: Base): boolean {
    let obj = this.selectedBases.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func4 Change the statue of the check box
  clickSelectNewBase(item) {
    this.zone.run(() => {
      if (this.itemSelected != undefined && this.itemSelected.internalId == item.internalId) {
        this.itemSelected = undefined;
        this.selectedBases = [];
      } else {
        this.itemSelected = item;
        this.selectedBases = [];
        this.selectedBases.push(item);
      }
    });
  }

  //func5 Change the statue of the check box
  clickSelectNewBase2() {
    if (this.itemSelected != undefined) {
      this.itemSelected = undefined;
      this.selectedBases = [];
      this.obj = false;
    } else {
      this.obj = true;
      this.itemSelected = this.newSensitiveBase[0];
      this.selectedBases = [];
      this.newSensitiveBase.forEach((e: Base) => {
        this.selectedBases.push(e);
      })
    }
  }

  //func6 Show the alert if we choose more then one base
  showAlert() {
    let str: string;
    this.translate.get('ONE_BASE').subscribe((res: string) => {
      str = res;
    });

    this.toastr.error(str, '', {
      timeOut: 2000,
      positionClass: 'toast-bottom-right'
    });
  }

  //func7 Close the modal and open the new modal RecommenderInfo11Component
  close() {
    this.dialogRef.close();
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
      baseSelected: this.navCtrl.get('baseSelected'),
      symptomsSelected: this.navCtrl.get('symptomsSelected'),
      questionAnswer: this.questionsAnswer,
      allBases: this.navCtrl.get('allBases'),
      newBases: this.navCtrl.get('newBases'),
      allergies: this.allergies,
      finish: 'yes'
    });
  }

  //func8 Navigate to the route /base-step1
  goBase() {
    this.modalService.dismissAll();
    this.router.navigate(['/base-step1']);
  }

  //func9 Change the base selected
  changeBase() {
    if (this.selectedBases.length != 0) {
      if (this.extraBase == true) {
        this.newBases = [];
        this.newBases.push(this.selectedBases[0]);
        this.baseSelected = this.selectedBases[1];
        this.dialogRef.close();
        this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
          baseSelected: this.baseSelected,
          symptomsSelected: this.navCtrl.get('symptomsSelected'),
          questionAnswer: this.questionsAnswer,
          allergies: this.allergies,
          newBases: this.newBases,
          allBases: this.navCtrl.get('allBases'),
          finish: 'yes'
        });

      } else {
        this.baseSelected = this.selectedBases[0];
        this.dialogRef.close();
        this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
          baseSelected: this.baseSelected,
          symptomsSelected: this.navCtrl.get('symptomsSelected'),
          questionAnswer: this.questionsAnswer,
          newBases: this.navCtrl.get('newBases'),
          allBases: this.navCtrl.get('allBases'),
          allergies: this.allergies,
          finish: 'yes'
        });
      }
    }
    else {
      this.dialogRef.close();
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
        baseSelected: this.navCtrl.get('baseSelected'),
        symptomsSelected: this.navCtrl.get('symptomsSelected'),
        questionAnswer: this.questionsAnswer,
        newBases: this.navCtrl.get('newBases'),
        allBases: this.navCtrl.get('allBases'),
        allergies: this.allergies,
        finish: 'yes'
      });
    }
  }
}
