import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ToastrService } from 'ngx-toastr';
import { Active } from 'src/models/Active/active';
import { Base } from 'src/models/base/base';
import { TextIsoValue } from 'src/models/enum/textIsoValue';

@Component({
  selector: 'app-normal-base-popup',
  templateUrl: './normal-base-popup.component.html',
  styleUrls: ['./normal-base-popup.component.scss']
})
export class NormalBasePopupComponent implements OnInit {
  public selectedBases: Base[] = [];
  public obj: boolean = false;
  public newBases: Base[] = [];
  public baseSelected: Base;
  public newBasesExist: boolean = false;
  public allBases: Base[];
  public basesFiltred: Base[];
  public newNormalBase: Base[] = [];
  public allergies: Active[] = [];
  public itemSelected: Base = null;
  public allergy: Active;
  public lang = localStorage.getItem('appLang');


  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private toastr: ToastrService,
    private navCtrl: NgxNavigationWithDataComponent,
    public dialogRef: MatDialogRef<NormalBasePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //@Input() public allergies;
  ngOnInit() {
    this.newBases = this.navCtrl.get("newBases");
    if (this.newBases != null) {
      this.newBasesExist = true;
    }
    this.allergies = this.data.allergies;
    this.newNormalBase = this.data.newNormalBase;
  }

  //func1 Check if the item is selected or no
  isSelected(item: Base): boolean {
    let obj = this.selectedBases.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func2 Change the statue of the check box
  clickSelectNewBase(item) {
    this.zone.run(() => {
      let obj = this.selectedBases.filter(p => p.internalId == item.internalId);
      if (obj != undefined && obj[0] != undefined) {
        this.selectedBases = this.selectedBases.filter(p => p.internalId != item.internalId);
      } else {
        if (this.selectedBases.length == 0) {
          this.selectedBases.push(item);
        } else {
          this.showAlert();
        }
      }
    });
  }

  //func3 Change the statue of the check box
  clickSelectNewBase2() {
    if (this.itemSelected != undefined) {
      this.itemSelected = undefined;
      this.selectedBases = [];
      this.obj = false;
    } else {
      this.obj = true;
      this.itemSelected = this.newNormalBase[0];
      this.selectedBases = [];
      this.newNormalBase.forEach((e: Base) => {
        this.selectedBases.push(e);
      })
    }
  }

  //func4 Show the alert if we choose more then one base
  showAlert() {
    let str: string;
    this.translate.get('ONE_BASE').subscribe((res: string) => {
      str = res;
    });

    this.toastr.error(str, '', {
      timeOut: 2000,
      positionClass: 'toast-bottom-right'
    });
  }

  //func5 Close the modal and open the new modal RecommenderInfo11Component
  close() {
    this.dialogRef.close();
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
      baseSelected: this.navCtrl.get('baseSelected'),
      symptomsSelected: this.navCtrl.get('symptomsSelected'),
      questionAnswer: this.navCtrl.get('questionAnswer'),
      newBases: this.navCtrl.get('newBases'),
      allBases: this.navCtrl.get('allBases'),
      allergies: this.allergies,
      finish: 'yes'
    });
  }

  //func6 Change the base selected
  changeBase() {
    if (this.selectedBases.length != 0) {
      if (this.newBasesExist == true) {
        this.newBases = [];
        this.newBases.push(this.selectedBases[0]);
        this.baseSelected = this.selectedBases[1];
        this.dialogRef.close();
        this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
          baseSelected: this.baseSelected,
          symptomsSelected: this.navCtrl.get('symptomsSelected'),
          questionAnswer: this.navCtrl.get('questionAnswer'),
          allergies: this.allergies,
          newBases: this.newBases,
          allBases: this.navCtrl.get('allBases'),
          finish: 'yes'
        });
      } else {
        this.baseSelected = this.selectedBases[0];
        this.dialogRef.close();
        this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
          baseSelected: this.baseSelected,
          symptomsSelected: this.navCtrl.get('symptomsSelected'),
          questionAnswer: this.navCtrl.get('questionAnswer'),
          newBases: this.navCtrl.get('newBases'),
          allBases: this.navCtrl.get('allBases'),
          allergies: this.allergies,
          finish: 'yes'
        });
      }
    } else {
      this.dialogRef.close();
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/device', {
        baseSelected: this.navCtrl.get('baseSelected'),
        symptomsSelected: this.navCtrl.get('symptomsSelected'),
        questionAnswer: this.navCtrl.get('questionAnswer'),
        newBases: this.navCtrl.get('newBases'),
        allBases: this.navCtrl.get('allBases'),
        allergies: this.allergies,
        finish: 'yes'
      });
    }
  }

  //func7 Get the translate name of the item with type TextIsoValue
  getBaseName(lst: TextIsoValue[], defaultName: string): string {

    let finded = lst.filter(p => p.iso == this.lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }
}
