import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Base } from 'src/models/base/base';
import { CustomerFinalRecommender } from 'src/models/customerFinalRecommender/customerFinalRecommender';
import { Device } from 'src/models/Device/device';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { TextIsoValues } from 'src/models/enum/textIsoValues';
import { QuestionAnswer } from 'src/models/question/questionAnswer';
import { SymptomAssetResult } from 'src/models/symptom/symptomAssetResult';
import { Symptom } from 'src/models/symptom/symtom';
import { CustomerFinalRecommenderServiceProvider } from 'src/providers/customer-final-recommender-service';
import { CustomerApiRestProvider } from 'src/providers/customer-service';
import { DeviceServiceProvider } from 'src/providers/device-service';
import { PrestashopURLsProvider } from 'src/providers/prestaShopURLs';
import { SymptomServiceProvider } from 'src/providers/symptom-service';
import { Globals } from '../globals';
import { ForgetEmailComponent } from './../forget-email/forget-email.component';

@Component({
  selector: 'app-final-recommendation',
  templateUrl: './final-recommendation.component.html',
  styleUrls: ['./final-recommendation.component.scss']
})
export class FinalRecommendationComponent implements OnInit {
  public questionAnswers: QuestionAnswer[] = [];
  public baseSelected: Base;
  private userId;
  public newBases: Base[] = [];
  public questions: QuestionAnswer[] = [];
  private symptomsSelected: Symptom[];
  public lstResultSymptoms: SymptomAssetResult[];
  private idsToShop: string;
  private customerEmail: string;
  public openAccordion: boolean = false;
  public urlCard: string = '';
  public device: Device;
  public retinol: number = 0;
  public retinolActive: number;
  public lesielleDevice: Device;
  public customerFinalRecommender: CustomerFinalRecommender;
  private newCustomerEmail: string = null;
  public devices: Device[];
  public selectedDevices: Device[] = [];
  public itemSelected: Device = null;
  public allergiesStr: string = "";
  public baseNew = 0;
  private clinicEmail: string = '';
  public lstResultSymptomsDaily: SymptomAssetResult[];
  public lstResultSymptomsOccasionally: SymptomAssetResult[];
  public occasionallyExist: boolean = false;
  public lang;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public sypmtomService: SymptomServiceProvider,
    private customerService: CustomerApiRestProvider,
    public psUrlBase: PrestashopURLsProvider,
    private globals: Globals,
    private zone: NgZone,
    private dialog: MatDialog,
    private router: Router,
    public addDeviceService: DeviceServiceProvider,
    private customer_final_recommender_service: CustomerFinalRecommenderServiceProvider,
  ) {
  }

  async ngOnInit() {
    this.lang = localStorage.getItem('appLang');
    this.globals.lastScreen = true;
    this.globals.navE = false;
    this.customerFinalRecommender = JSON.parse(sessionStorage.getItem('recommender'));
    this.lesielleDevice = JSON.parse(sessionStorage.getItem('device'));
    this.baseSelected = this.customerFinalRecommender.base;
    this.symptomsSelected = this.customerFinalRecommender.lstSymptom;
    this.questionAnswers = this.customerFinalRecommender.lstQuestionAnswer;
    this.newBases = this.customerFinalRecommender.newBases;
    this.customerEmail = this.customerFinalRecommender.customerEmail;
    this.device = this.customerFinalRecommender.device;
    this.addDeviceService.getAllDevice().subscribe(
      (data: Device[]) => {
        this.devices = data;
      }
    );
    this.idsToShop = this.baseSelected.idPs.toString();
    if (this.newBases != null) {
      this.newBases.forEach(element => {
        this.idsToShop += '-' + element.idPs.toString();
      });
    }

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        
        this.customerService.GetUserByEmail(this.customerEmail).subscribe(
          data => {
            this.userId = data;
            this.sypmtomService.getFullRecomendation(this.userId, this.baseSelected.internalId, sypmtIds, questIds, this.retinol).subscribe(
              (data: SymptomAssetResult[]) => {
                this.lstResultSymptoms = data;
                this.lstResultSymptomsDaily = this.lstResultSymptoms.filter(a => a.assetInternalId != 18 && a.assetInternalId != 13);
                this.lstResultSymptomsOccasionally = this.lstResultSymptoms.filter(a => a.assetInternalId == 18 || a.assetInternalId == 13);
                if (this.lstResultSymptomsOccasionally.length != 0) {
                  this.occasionallyExist = true;
                }
                this.lstResultSymptoms.forEach((element) => {
                  this.idsToShop += '-' + element.idPsAsset;
                });
                //let lang = localStorage.getItem('appLang');
                let country = localStorage.getItem("appCountry");
                let lang = localStorage.getItem("appLang");
                this.urlCard = this.psUrlBase.getFrontUrlBaseByCountryCode(country, lang);
                if (this.device != null) {
                  if (this.lang = "es")
                    this.urlCard += 'testr?p=' + this.idsToShop + '-' + this.device.idPs + '-120&q=1';
                  else
                    this.urlCard += 'testr?p=' + this.idsToShop + '-' + this.device.idPs + '&q=1';
                } else {
                  if (this.lang = "es")
                    this.urlCard += 'testr?p=' + this.idsToShop + '-120&q=1';
                  else
                    this.urlCard += 'testr?p=' + this.idsToShop + '&q=1';
                }
              },
              (error) => {
                console.log(error);
              }
            );
          },
          error => {
            console.log('Error', error);
          });
      }
     
    })


    let sypmtIds: number[] = this.symptomsSelected.map(function (a) {
      return a.internalId;
    });
    let questIds: number[] = this.questionAnswers.map(function (a) {
      return a.internalId;
    });

    this.retinolActive = JSON.parse(localStorage.getItem('retinol'));

    if (this.retinolActive != null) {
      this.retinol = this.retinolActive;
    }
    this.customerService.GetUserByEmail(this.customerEmail).subscribe(
      data => {
        this.userId = data;
        this.sypmtomService.getFullRecomendation(this.userId, this.baseSelected.internalId, sypmtIds, questIds, this.retinol).subscribe(
          (data: SymptomAssetResult[]) => {
            this.lstResultSymptoms = data;
            this.lstResultSymptomsDaily = this.lstResultSymptoms.filter(a => a.assetInternalId != 18 && a.assetInternalId != 13);
            this.lstResultSymptomsOccasionally = this.lstResultSymptoms.filter(a => a.assetInternalId == 18 || a.assetInternalId == 13);
            if (this.lstResultSymptomsOccasionally.length != 0) {
              this.occasionallyExist = true;
            }
            this.lstResultSymptoms.forEach((element) => {
              this.idsToShop += '-' + element.idPsAsset;
            });
            //let lang = localStorage.getItem('appLang');
            let country = localStorage.getItem("appCountry");
            this.urlCard = this.psUrlBase.getFrontUrlBaseByCountryCode(country, this.lang);
            if (this.device != null) {
              if (this.lang = "es")
                this.urlCard += 'testr?p=' + this.idsToShop + '-' + this.device.idPs + '-120&q=1';
              else
                this.urlCard += 'testr?p=' + this.idsToShop + '-' + this.device.idPs + '&q=1';
            } else {
              if (this.lang = "es")
                this.urlCard += 'testr?p=' + this.idsToShop + '-120&q=1';
              else
                this.urlCard += 'testr?p=' + this.idsToShop + '&q=1';
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      error => {
        console.log('Error', error);
      });
  }


  //func1 Get the translate name of the item with type TextIsoValue
  getName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    if (lst != null) {
      let finded = lst.filter(p => p.iso == lang)[0];
      if (finded == null) {
        return defaultName;
      }
      return finded.value;
    }
  }

  //func2 Get the translate name of the item with type TextIsoValue
  getImgName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func3 Get the translate name of the item with type TextIsoValues
  getRewards(lst: TextIsoValues[]): string[] {
    let lang = localStorage.getItem('appLang');
    if (lst == null) {
      return null;
    }
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return null;
    }
    return finded.value;
  }


  //func4 Open the popup to add customer email
  addEmail() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.customerFinalRecommender._id;
    if (window.innerWidth < 767) {
      dialogConfig.maxWidth = "80%";
    } else if (window.innerWidth < 1024) {
      dialogConfig.maxWidth = "80%";
    } else {
      dialogConfig.maxWidth = "900px";
    }
    const dialogRef = this.dialog.open(ForgetEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (data) => {
        this.newCustomerEmail = data;
        let sypmtIds: number[] = this.symptomsSelected.map(function (a) {
          return a.internalId;
        });
        let questIds: number[] = this.questionAnswers.map(function (a) {
          return a.internalId;
        });

        if (this.customerFinalRecommender.lstAllergy != null) {
          let allergieIds = this.customerFinalRecommender.lstAllergy.map(function (a) {
            return a.internalId;
          });
          this.allergiesStr = allergieIds.join(';');
        }
        if (this.newBases != null) {
          this.newBases.forEach(
            e => {
              this.baseNew = e.internalId;
            });
        }
        this.retinolActive = JSON.parse(localStorage.getItem('retinol'));
        if (this.retinolActive != null) {
          this.retinol = this.retinolActive;
        }
        //let langR = localStorage.getItem('appLang');

        let country = localStorage.getItem("appCountry");
        this.urlCard = this.psUrlBase.getFrontUrlBaseByCountryCode(country, this.lang);
        this.urlCard += 'testr?p=';
        console.log(data);
        this.customer_final_recommender_service.sendFullRecomendation(this.newCustomerEmail, this.lang, this.baseSelected.internalId, this.baseNew, this.allergiesStr, sypmtIds, questIds, this.clinicEmail, this.urlCard, this.lang, this.retinol)
          .toPromise();
        this.ngOnInit();
        console.log(this.newCustomerEmail);
      }
    );
  }

  //func5 Get devices to add a device if the customer forget to add it
  getDevices() {
    this.addDeviceService.getAllDevice().subscribe(
      (data: Device[]) => {
        this.devices = data;
      }
    );
  }

  //func6 Check if the item is checked or not
  isSelected(item: Device): boolean {
    let obj = this.selectedDevices.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func7 Change the statue of the check box
  clickSelectDevice(item) {
    this.zone.run(() => {
      this.itemSelected = item;
      this.addDevice(this.itemSelected.internalId);
    });
  }

  //func8 Add the device to the recommender result
  async addDevice(id: number) {
    this.customerFinalRecommender = await this.customer_final_recommender_service.updateCustomerFinalRecommenderDevice(this.customerFinalRecommender._id, id);
    sessionStorage.setItem('recommender', JSON.stringify(this.customerFinalRecommender));
    this.ngOnInit();
  }

  //func9 Open Accordion to see the other benefits
  open(i) {
    var x = document.getElementById(i);
    if (x.className.indexOf('w3-show') == -1) {
      x.className += ' w3-show';
      return (this.openAccordion = true);
    } else {
      x.className = x.className.replace(' w3-show', '');
      return (this.openAccordion = false);
    }
  }

  //Add to card
  goToCard() {
    window.open(this.urlCard, "_blank");
  }
}
