import { BaseTutoSt1Component } from './../base-tuto-st1/base-tuto-st1.component';
import { Base } from 'src/models/base/base';
import { BaseServiceProvider } from 'src/providers/base-service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseInfoSt1Component } from '../base-info-st1/base-info-st1.component';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Globals } from '../globals';
import { MatDialog, MatDialogConfig } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-base-st1',
  templateUrl: './base-st1.component.html',
  styleUrls: ['./base-st1.component.scss']
})

export class BaseSt1Component {
  public allBase: Base[];
  public baseNormalGrouped: Base[] = [];
  public baseSensitiveGrouped: Base[] = [];
  public baseSelected: Base;
  public baseTuto: Base;
  private navE: boolean;
  public baseSensible = null;
  loader = true;
  totalCount = 4;
  imgSrc: string = "assets/imgs/btn-info-lesielleB.svg";

  constructor(
    private baseService: BaseServiceProvider,
    private router: Router,
    private modalService: NgbModal,
    public navCtrl: NgxNavigationWithDataComponent,
    private globals: Globals,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.navE = globals.navE;
  }


  ngOnInit() {
    sessionStorage.clear();
    setInterval(() => {
      this.loader = false;
    }, 3000);
    this.globals.nav1Per = 33;
    this.globals.navE = true;
    this.baseService.getAllBases()
      .subscribe(
        (data: Base[]) => {
          this.allBase = data.sort((a, b) => a.internalId - b.internalId);
          this.groupNormalBases(this.allBase);
          this.groupSensitiveBases(this.allBase);
          this.loader = false;
        },
        err => {
          console.log('error bases');
          console.log(err);
          console.log(err.name == 'TimeoutError');
        }
      );
  }

  //Change icon on mouse over
  onMouseOver(): void {
    this.imgSrc = "assets/imgs/btn-info-lesielleW.svg";
  }

  //Change icon on mouse out
  onMouseOut(): void {
    this.imgSrc = "assets/imgs/btn-info-lesielleB.svg";
  }

  //func1 Get the item checked (Base) and send them to the local storage "baseSelected"
  clickSelectBase(base) {
    if (base == this.baseSelected) {
      if (this.baseSelected != null) {
        this.baseSelected = null;
      } else {
        this.baseSelected = base;
      }
    } else {
      this.baseSelected = base;
    }
  }

  // Check language and go to the next page
  nextPage(){
    /*if (localStorage.getItem("appCountry") == "tr") {
     this.navCtrl.navigate('active-step1', {
        /*baseSelected: this.basesFiltered[0],
        baseSelected0: this.navCtrl.get('baseSelected0'),
        clinicId: this.navCtrl.get('clinicId'),
        allBases: this.navCtrl.get('allBases')
      });
    }else{*/
      this.gobaseSt2();
    //}
  }
  //func2 Navigate to the route /base-step2
  gobaseSt2() {
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/base-step2', {
      clinicId: this.navCtrl.get('clinicId'),
      baseSelected0: this.baseSelected,
      allBases: this.allBase
    });
  }

  //func3 Navigate to the first page
  goback() {
    this.router.navigate(['/' + localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang")]);
  }

  //func4 Group the Base by Type (Normal)
  groupNormalBases(listBases) {
    let lstTypes: number[] = [];
    listBases.forEach((element: Base) => {
      if (element.sensitive == false && lstTypes.indexOf(element.type) == -1) {
        lstTypes.push(element.type);
        this.baseNormalGrouped.push(element);
      }
    });
  }

  //func5 Group the Base by Type (Sensible)
  groupSensitiveBases(listBases) {
    let lstTypes: number[] = [];
    listBases.forEach((element: Base) => {
      if (element.sensitive == true && lstTypes.indexOf(element.type) == -1) {
        lstTypes.push(element.type);
        this.baseSensitiveGrouped.push(element);
      }
    });
  }

  //func6 Get the translate name of the item with type TextIsoValue
  getBaseName(lst: TextIsoValue[], defaultName: string): string {
    let lang = localStorage.getItem('appLang');
    let finded = lst.filter(p => p.iso == lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func7 Select non sensitive bases
  clickSelectBaseNormal() {
    this.baseSelected = null;
    if (this.baseSensible == false) {
      this.baseSensible = null;
    } else {
      this.baseSensible = false;
    }
  }

  //func8 select sensitive bases
  clickSelectBaseSensitive() {
    this.baseSelected = null;
    if (this.baseSensible == true) {
      this.baseSensible = null;
    } else {
      this.baseSensible = true;
    }
  }

  //func9: Open the base information 1 modal
  open() {
    this.modalService.open(BaseInfoSt1Component);
  }

  //func10 open the base tutorial to know the skin type
  goBaseTuto() {
    this.baseSelected = null;
    this.baseSensible = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.allBase;
    if (window.innerWidth < 767) {
      dialogConfig.width = "80%";
    } else if (window.innerWidth < 1024) {
      dialogConfig.width = "80%";
    } else {
      dialogConfig.width = "900px";
    }
    const dialogRef = this.dialog.open(BaseTutoSt1Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (data) => {
        this.baseTuto = data;
        if (this.baseTuto != null) {
          if (this.baseTuto.sensitive == true) {
            this.clickSelectBaseSensitive();
            this.clickSelectBase(this.baseTuto);
            this.baseSensible = true;
          } else {
            this.clickSelectBaseNormal();
            this.clickSelectBase(this.baseTuto);
            this.baseSensible = false;
          }
        }
      }
    );

  }
}
