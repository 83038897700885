import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, NavigationStart, Params, Router, RouterEvent} from '@angular/router';
import {Subscription} from 'rxjs';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'lesielle-recommender';
  defaultLang: string = 'en';
  languageSelected: string = 'en';
  subscription: Subscription;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-178415825-1', {
        'page_path': event.urlAfterRedirects
      });
    });
    
  }

  ngOnInit() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        let matches = event.url.match(/^\/([a-zA-Z0-9]+)\/(..)/);
        if (matches != null && ["tr","es","en"].includes(matches[2])) {
          let lang = matches[2];
          if(lang == "tr"){
            this.translate.use("tr");
            localStorage.setItem('appLang', "tr");
            this.translate.setDefaultLang("tr");
          }else if(lang == "es"){
            this.translate.use("es");
            localStorage.setItem('appLang', "es");
            this.translate.setDefaultLang("es");
          }else if(lang == "en"){
            this.translate.use("en");
            localStorage.setItem('appLang', "en");
            this.translate.setDefaultLang("en");
          }
        } else if (matches != null){
          //temporal
          this.translate.use("en");
          localStorage.setItem('appLang', "en");
          this.translate.setDefaultLang("en");
          /*let lang = matches[2];
          if(lang != null){
            this.translate.use("en");
            localStorage.setItem('appLang', lang);
            this.translate.setDefaultLang("en");
          }else{
            let browserLang = this.translate.getBrowserLang();
            if (browserLang == 'es') {
              localStorage.setItem('appLang', 'es');
              this.translate.setDefaultLang('es');
              this.translate.use("es");
            } else if (browserLang == 'en'){
              localStorage.setItem('appLang', 'en');
              this.translate.setDefaultLang('en');
              this.translate.use("en");
            }else if (browserLang == 'en'){
              localStorage.setItem('appLang', 'tr');
              this.translate.setDefaultLang('tr');
              this.translate.use("tr");
            }else{
              localStorage.setItem('appLang', 'en');
              this.translate.setDefaultLang('en');
              this.translate.use("en");
            }
          }*/
        }else{
          //temporal
          this.translate.use("en");
          localStorage.setItem('appLang', "en");
          this.translate.setDefaultLang("en");
          /*
          let browserLang = this.translate.getBrowserLang();
          if (browserLang == 'es') {
            localStorage.setItem('appLang', 'es');
            this.translate.setDefaultLang('es');
            this.translate.use("es");
          } else if (browserLang == 'en'){
            localStorage.setItem('appLang', 'en');
            this.translate.setDefaultLang('en');
            this.translate.use("en");
          }else if (browserLang == 'en'){
            localStorage.setItem('appLang', 'tr');
            this.translate.setDefaultLang('tr');
            this.translate.use("tr");
          }else{
            localStorage.setItem('appLang', 'en');
            this.translate.setDefaultLang('en');
            this.translate.use("en");
          }*/
        }
        if (matches != null && ["es", "tr", "int"].includes(matches[1])) {
          let country = matches[1];
          localStorage.setItem('appCountry', country);
        } else if(matches != null) {
          let country = matches[1];
          localStorage.setItem('appCountry', country);
        }else{
          localStorage.setItem('appCountry', "int");

        }
      }
    });
  }

  //func1
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
