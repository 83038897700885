import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Active } from '../models/Active/active';
import { Advice } from './../models/Advice/advice';
import { GlobalEnvironmentProvider } from './global-service';

/*
  Generated class for the AssetServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AssetServiceProvider {

  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getAllAssets() {
    let url = this.apiUrl + 'asset/GetAssets';
    return this.http.get(url);
  }

  getRetinolAsset() {
    let url = this.apiUrl + 'asset/GetAsset?assetId=' + 20;
    return this.http.get<Active>(url);
  }

  getAssetByID(id: number) {
    let url = this.apiUrl + 'asset/GetAsset?assetId=' + id;
    return this.http.get<Active>(url);
  }

  getAdvicesByRecommender(id: number) {
    let url = this.apiUrl + 'asset/GetAdivicesByRecommender?recommenderId=' + id;
    return this.http.get<Advice[]>(url);
  }
}
