import {Symptom} from '../symptom/symtom';
import {QuestionAnswer} from '../question/questionAnswer';
import {Active} from '../Active/active';
import {Base} from '../base/base';
import { Device } from '../Device/device';

export class CustomerFinalRecommender {
  public _id: string;
  public customerEmail: string;
  public customerID: string;
  public date: Date;
  public base: Base;
  public clinicId: string;
  public lstSymptom: Symptom[];
  public lstQuestionAnswer: QuestionAnswer[];
  public lstAllergy: Active[];
  public lstActives: Active[];
  public newBases: Base[];
  public language: string;
  public device: Device;
  public url: string;

  constructor() {
  }
}

