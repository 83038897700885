import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalEnvironmentProvider } from './global-service';

/*
  Generated class for the SypmtomServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class SymptomServiceProvider {
  private apiUrl: string;

  constructor(
    public http: HttpClient,
    public globalEnvironment: GlobalEnvironmentProvider) {
    this.apiUrl = globalEnvironment.GetApiServerUrl();
  }

  getAllSypmtoms() {
    let url = this.apiUrl + 'sypmtom/GetSypmtoms';
    return this.http.get(url);
  }

  getAllSymptomsQuestions(baseId: number, symptoms: number[]) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'questionactive/GetQuestionsWeb?b=' + baseId + '&s=' + symptoms.join(';');
    return this.http.get(url, { headers: headers });
  }


  getAllSymptomsQuestions1(baseId: number, symptoms: number[]) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'sypmtom/GetQuestionsWeb?b=' + baseId + '&s=' + symptoms.join(';');
    return this.http.get(url, { headers: headers });
  }

  getFullRecomendation(userId: string, baseId: number, sypmtoms: number[], questions: number[], retinol: number) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', '0');
    let url = this.apiUrl + 'sypmtom/getFullRecomendation?u=' + userId + '&b=' + baseId + '&s=' + sypmtoms.join(';') + '&q=' + questions.join(';') + '&retinol=' + retinol;
    return this.http.get(url, { headers: headers });
  }

}
