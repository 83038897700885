import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ToastrService } from 'ngx-toastr';
import { Base } from 'src/models/base/base';
import { TextIsoValue } from 'src/models/enum/textIsoValue';
import { BaseServiceProvider } from 'src/providers/base-service';


@Component({
  selector: 'app-addional-base-st1',
  templateUrl: './addional-base-st1.component.html',
  styleUrls: ['./addional-base-st1.component.scss'],
})
export class AddionalBaseSt1Component implements OnInit {
  public withSpf: boolean;
  public newBase: Base[] = [];
  public selectedBases: Base[] = [];
  public itemSelected: Base = null;
  public baseFinal: Base;
  private baseSelected;
  private baseSelected0;
  private allBases;
  private clinicId;
  public lang = localStorage.getItem('appLang');

  constructor(
    public baseService: BaseServiceProvider,
    private zone: NgZone,
    private translate: TranslateService,
    private toastr: ToastrService,
    private navCtrl: NgxNavigationWithDataComponent,
    public dialogRef: MatDialogRef<AddionalBaseSt1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.baseSelected = this.data.baseSelected;
    this.baseSelected0 = this.data.baseSelected0;
    this.allBases = this.data.allBases;
    this.clinicId = this.data.clinicId;
    this.baseFinal = this.navCtrl.get('baseSelected');
    if (this.baseSelected) {
      this.baseFinal = this.baseSelected;
    }
    this.baseService.getAllBases().subscribe(
      (data: Base[]) => {
        this.allBases = data;
        if (this.baseFinal.spf.type == 0) {
          this.withSpf = false;
          this.getNewBaseWithSPF(this.allBases);
        } else {
          this.withSpf = true;
          this.getNewBaseWithoutSPF(this.allBases);
        }
      },
      err => {
        console.log('error bases');
        console.log(err);
        console.log(err.name == 'TimeoutError');
      }
    );
  }

  //func1 Check if the item is checked or not
  isSelected(item: Base): boolean {
    let obj = this.selectedBases.filter(p => p.internalId == item.internalId);
    return obj != undefined && obj[0] != undefined && obj[0].internalId != 0;
  }

  //func2 Change the statue of the check box
  clickSelectNewBase(item: Base) {
    this.zone.run(() => {
      if (this.itemSelected != undefined && this.itemSelected.internalId == item.internalId) {
        this.itemSelected = undefined;
        this.selectedBases = [];
      } else {
        this.itemSelected = item;
        this.selectedBases=[];
        this.selectedBases.push(item);
      }
    });
  }

  //func3 Add the new Base chosen to the local storage and navigate to the route /registration
  addNewBase() {
    if (this.selectedBases.length != 0) {
      this.dialogRef.close();
      this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/active-step1', {
        baseSelected: this.baseSelected,
        baseSelected0: this.baseSelected0,
        allBases: this.allBases,
        newBases: this.selectedBases,
        clinicId: this.clinicId
      });
    }
  }

  //func4 Check if there are bases with the same type & withOUT SPF and get theme all
  getNewBaseWithoutSPF(listBases) {
    var exactBase: Base = null;
    var newBases: Base[] = [];
    this.allBases.forEach((element: Base) => {
      if ((element.defaultName == this.baseFinal.defaultName) &&
        (element.texture.type == this.baseFinal.texture.type) &&
        (element.line.type == this.baseFinal.line.type) &&
        (element.spf.type == 0)) {
        exactBase = element;
      } else if ((element.defaultName == this.baseFinal.defaultName) && (element.spf.type == 0)) {
        newBases.push(element);
      }
    });
    if (exactBase != null) {
      this.newBase.push(exactBase);
    } else {
      this.newBase = newBases
    }
  }

  //func5 Check if there are bases with the same type & with SPF and get theme all
  getNewBaseWithSPF(listBases) {
    var exactBase: Base = null;
    var newBases: Base[] = [];
    this.allBases.forEach((element: Base) => {
      if ((element.defaultName == this.baseFinal.defaultName) &&
        (element.texture.type == this.baseFinal.texture.type) &&
        (element.line.type == this.baseFinal.line.type) &&
        (element.spf.type == 1)) {
        exactBase = element;
      } else if ((element.defaultName == this.baseFinal.defaultName) && (element.spf.type == 1)) {
        newBases.push(element);
      }
    });
    if (exactBase != null) {
      this.newBase.push(exactBase);
    } else {
      this.newBase = newBases
    }
  }

  //func6 Get the translate name of the item with type TextIsoValue
  getBaseName(lst: TextIsoValue[], defaultName: string): string {
    let finded = lst.filter(p => p.iso == this.lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }

  //func7 Close the modal and navigate to active step 1
  close() {
    this.dialogRef.close();
    this.navCtrl.navigate(localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang") + '/active-step1', {
      baseSelected: this.baseSelected,
      baseSelected0: this.baseSelected0,
      allBases: this.allBases,
      clinicId: this.clinicId
    });
  }

  //func8 Show the alert if we choose more then one base
  showAlert() {
    let str: string;
    this.translate.get('ONE_BASE').subscribe((res: string) => {
      str = res;
    });
    this.toastr.error(str, '', {
      timeOut: 2000,
      positionClass: 'toast-bottom-right'
    });
  }

  //func9 Get the translate name of the item with type TextIsoValue
  getImgName(lst: TextIsoValue[], defaultName: string): string {
    let finded = lst.filter(p => p.iso == this.lang)[0];
    if (finded == null) {
      return defaultName;
    }
    return finded.value;
  }
}
