import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {NgxNavigationWithDataComponent} from 'ngx-navigation-with-data';

@Injectable()
export class SymptomSt2GuardService implements CanActivate {
  constructor(
    private router: Router,
    private navCtrl: NgxNavigationWithDataComponent
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.navCtrl.get('symptomsSelected')) {
      return true;
    } else {
      this.router.navigate(['/' + localStorage.getItem("appCountry") + "/"+ localStorage.getItem("appLang")]);
    }
  }
}
