import {Injectable} from '@angular/core';
import 'rxjs/Rx';

/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PrestashopURLsProvider {

  private urls = [
    {
      idShop: 1,
      isoCode: 'es',
      langCode: 'es',
      urlBase: 'https://www.lesielle.com/es/',
      forgotPwd: 'recuperacion-contrasena'
    },
    {
      idShop: 2,
      isoCode: 'fr',
      langCode: 'fr',
      urlBase: 'https://www.lesielle.com/fr/',
      forgotPwd: 'mot-passe'
    },
    {
      idShop: 3,
      isoCode: 'ch',
      langCode: 'de',
      urlBase: 'https://www.lesielle.com/ch/de/',
      forgotPwd: 'passwort-vergessen'
    },
    {
      idShop: 4,
      isoCode: 'de',
      langCode: 'de',
      urlBase: 'https://www.lesielle.com/de/',
      forgotPwd: 'passwort-vergessen'
    },
    {
      idShop: 5,
      isoCode: 'uk',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/uk/',
      forgotPwd: 'password-recovery'
    },
    {
      idShop: 6,
      isoCode: 'it',
      langCode: 'it',
      urlBase: 'https://www.lesielle.com/it/',
      forgotPwd: ''
    },
    {
      idShop: 7,
      isoCode: 'int',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/int/en/',
      forgotPwd: 'password-recovery'
    },
    {
      idShop: 8,
      isoCode: 'int',
      langCode: 'es',
      urlBase: 'https://www.lesielle.com/int/es/',
      forgotPwd: 'recuperacion-contrasena'
    },
    {
      idShop: 9,
      isoCode: 'int',
      langCode: 'fr',
      urlBase: 'https://www.lesielle.com/int/fr/',
      forgotPwd: 'mot-passe'
    },
    {
      idShop: 10,
      isoCode: 'int',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/int/de/',
      forgotPwd: 'passwort-vergessen'
    },
    {
      idShop: 11,
      isoCode: 'us',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/us/',
      forgotPwd: 'password-recovery'
    },
    {
      idShop: 12,
      isoCode: 'ch',
      langCode: 'fr',
      urlBase: 'https://www.lesielle.com/ch/fr/',
      forgotPwd: 'passwort-vergessen'
    },
    {
      idShop: 13,
      isoCode: 'fr',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/fr/',
      forgotPwd: 'mot-passe'
    },
    {
      idShop: 14,
      isoCode: 'de',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/de/',
      forgotPwd: 'passwort-vergessen'
    },
    {
      idShop: 15,
      isoCode: 'it',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/it/',
      forgotPwd: ''
    },
    {
      idShop: 15,
      isoCode: 'ch',
      langCode: 'en',
      urlBase: 'https://www.lesielle.com/ch/fr/',
      forgotPwd: ''
    }
  ];

  constructor() {
  }

  //Not in use
  /*getFrontUrlBaseByIdShop(idShop: number) {
    let found = this.urls.filter(p => p.idShop == idShop);
    if (found != null && found.length > 0) {
      return found[0].urlBase;
    }
    return this.urls.filter(p => p.idShop == 7)[0].urlBase;
  }*/

  getFrontUrlBaseByCountryCode(isoCode: string, langCode: string) {
    let found = this.urls.filter(p => {return (p.isoCode == isoCode && p.langCode == langCode)});
    if (found != null && found.length > 0) {
      return found[0].urlBase;
    }
    return this.urls.filter(p => p.idShop == 7)[0].urlBase;
  }

  //Not in use
  /*getFrontUrlForgotPwdByCountryCode(isoCode: string): string {
    let found = this.urls.filter(p => p.isoCode == isoCode);
    if (found != null && found.length > 0) {
      return found[0].urlBase + found[0].forgotPwd;
    }
    return this.urls.filter(p => p.idShop == 7)[0].urlBase + this.urls.filter(p => p.idShop == 7)[0].forgotPwd;
  }*/

}
