import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-base-info-st2',
  templateUrl: './base-info-st2.component.html',
  styleUrls: ['./base-info-st2.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class BaseInfoSt2Component implements OnInit {
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<BaseInfoSt2Component>,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
  }

  //func1 Open Modal
  open(content) {
    this.modalService.open(content);
  }

  //func2 Close the Modal
  close() {
    this.dialogRef.close()
  }
}
